import Highway from '@refletdigital/highway';
import * as Choices from "choices.js";
import {init} from '../init';
import {getGoogleRecaptcha, isBrowserOutdated, scrollWithAnchor} from "../utils/utils";
import {COLOR_BLUE, PIN_PATH} from "../utils/constants";

class Contact extends Highway.Renderer {
  onEnter() {
    init();

    this.el = document.querySelector('.contact');
    this.form = this.el.querySelector('.contact__form');

    if (!isBrowserOutdated()) {
      const selects = document.querySelectorAll('select');
      for (let select of selects) {
        new Choices(select, {
          searchEnabled: false,
          classNames: {
            containerOuter: select.classList.contains('form__field--error') ? 'choices is-error' : 'choices'
          },
          shouldSort: false
        });
      }
    }

    getGoogleRecaptcha().then(() => {
      this.onReCaptchaReady();
    });

    if (this.form.classList.contains('contact__form--success')) {
      dataLayer.push({
        event: `Envoi ${this.el.getAttribute('data-form-name')}`
      })
      scrollWithAnchor('#contact_submit');
    }
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {

  }

  onReCaptchaReady() {
    var form = this.el.querySelector('form');
    var container = form.querySelector('.g-recaptcha');
    if (container) {
      var params = {
        sitekey: container.getAttribute('data-sitekey'),
        callback: this.onReCaptchaFormSubmit.bind(form)
      };
      if (container.hasAttribute('data-size')) {
        params.size = container.getAttribute('data-size');
      }
      if (container.hasAttribute('data-badge')) {
        params.badge = container.getAttribute('data-badge');
      }
      grecaptcha.render(form.querySelector('[type=submit]'), params);
    }
  }

  onReCaptchaFormSubmit(token) {
    this.querySelector('.g-recaptcha').value = token;
    if (this.hasAttribute('data-event')) {
      this.dispatchEvent(new Event(this.getAttribute('data-event')));
    } else {
      this.submit();
    }
  }
}

export default Contact;
