import { TweenLite } from 'gsap';
import { EASE_DEFAULT } from './../utils/constants';
import styles from './../../scss/abstracts/_variables.scss';

class Footer {
  constructor() {
    this.hasSubmenuSet = document.querySelectorAll('.footer-more');
    this.bindUIActions();
  }

  bindUIActions() {
    for (let item of this.hasSubmenuSet) {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        const menu = this.parentElement;
        const submenu = menu.querySelector('.footer-nav__submenu');

        if (menu.classList.contains('open')) {
          menu.classList.remove('open');
          TweenLite.to(submenu, 0.25, {maxHeight: 0, ease: EASE_DEFAULT});
        } else {
          menu.classList.add('open');
          TweenLite.to(submenu, 0.25, {maxHeight: submenu.scrollHeight, ease: EASE_DEFAULT});
        }
      });
    }
  }
}

export default Footer;
