import {TimelineLite} from "gsap";
import {EASE_DEFAULT} from "../utils/constants";

class Breadcrumb {

  constructor(el) {
    this.el = el;
    this.itemWithSublist = this.el.querySelector('.breadcrumb__item--with-submenu');

    if (this.itemWithSublist) {
      this.linkWithSublist = this.itemWithSublist && this.itemWithSublist.querySelector('.breadcrumb__link');
      this.icon = this.linkWithSublist.querySelector('.svg-icon');
      this.subList = this.itemWithSublist.querySelector('.breadcrumb__sublist');
      this.subListItems = this.subList.querySelectorAll('.breadcrumb__item');
      this.isSubListOpen = false;

      this.timeline = new TimelineLite({ paused:true });
      this.timeline.set(this.subList, {y: '-10px', visibility: 'visible'});
      this.timeline.set(this.subListItems, {x: '-10px', opacity: 0});
      this.timeline.to(this.icon, 0.25, {rotation: 180, ease: EASE_DEFAULT});
      this.timeline.to(this.subList, 0.25, {y: '0px', opacity: 1, ease: EASE_DEFAULT});
      this.timeline.staggerTo(this.subListItems, 0.25, {x: '0px', opacity: 1, ease: EASE_DEFAULT}, 0.05, '=-0.1');
    }

    this.bindUIActions();
  }

  /**
   * On link with subtitle click
   */
  onLinkWithSubtitleClick() {
    this.toggleSublist();
  }

  /**
   * On body click
   * Used to close sublist
   * @param e
   */
  onBodyClick(e) {
    if(e.target !== this.linkWithSublist && this.isSubListOpen) {
      this.closeSublist();
    }
  }

  /**
   * Simple toggle
   */
  toggleSublist() {
    this.isSubListOpen ? this.closeSublist() : this.openSublist();
  }

  /**
   * Play sublist timeline
   */
  openSublist() {
    this.timeline.play();
    this.isSubListOpen = true;
  }

  /**
   * Reverse sublist timeline
   */
  closeSublist() {
    this.timeline.reverse();
    this.isSubListOpen = false;
  }

  /**
   * Bind UI Actions
   */
  bindUIActions() {
    if (this.linkWithSublist) {
      this.linkWithSublist.addEventListener('click', () => this.onLinkWithSubtitleClick());
      document.body.addEventListener('click', (e) => this.onBodyClick(e));
    }
  }
}

export default Breadcrumb;
