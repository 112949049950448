import {
  BREAKPOINT_FULL,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
  BREAKPOINT_XLARGE,
  WEEK,
} from "./constants";

/**
 * Is browser outdated
 * Return true if is IE<=10
 * @returns {boolean}
 */
export const isBrowserOutdated = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return (msie > 0);
};

/**
 * Is IE
 * Return true if is IE<=11
 * @returns {boolean}
 */
export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
};

/**
 * Get Viewport Width
 * @returns {number}
 */
export const getViewportWidth = () => {
  return window.innerWidth;
};

/**
 * Scroll to with a promise
 * @param posY int target position
 * @returns {Promise<any>}
 */
export const scrollToPromise = (posY) => {
  return new Promise((resolve) => {

    const parsedPosY = parseInt(posY);

    const isReached = () => {
      if (parseInt(document.documentElement.scrollTop) === parsedPosY) {
        window.removeEventListener('scroll', isReached);
        resolve();
      }
    };

    isReached();
    window.addEventListener('scroll', isReached);

    scrollTo(parsedPosY);
  });
};

/**
 * Scroll To
 * Include fallback for IE10
 * Use isBrowserOutdated function
 * @param posY int
 */
export const scrollTo = (posY) => {
  if (isBrowserOutdated()) {
    const body = document.body,
      html = document.documentElement,
      height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - document.documentElement.clientHeight,
      origin = document.documentElement.scrollTop,
      target = posY,
      direction = target - origin > 0,
      offset = direction ? 10 : - 10;

    if ((direction && origin + offset > target) ||
      (!direction && origin + offset < target) ||
      origin + offset > height ||
      origin + offset < 0) {
      window.scrollTo(0, target)
    } else {
      setTimeout(() => {
        window.scrollTo(0, origin + offset);
        scrollTo(target);
      }, 5)
    }
  } else {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: parseInt(posY)
    });
  }
};

/**
 *
 * @param e
 * @param link
 */
export const scrollToAnchor = (link) => {
  const targetUrl = new URL(link.href);
  const anchorElement = document.querySelector(targetUrl.hash);
  if (anchorElement) {
    const headerHeight = document.querySelector('.header').offsetHeight;
    const topMargin = 10;
    const scrollValue = anchorElement.getBoundingClientRect().top + document.documentElement.scrollTop - topMargin - headerHeight;
    scrollTo(scrollValue);
  }
};

/**
 *
 * @param anchor
 */
export const scrollWithAnchor = (anchor) => {
  const anchorElement = document.querySelector(anchor);
  if (anchorElement) {
    const headerHeight = document.querySelector('.header').offsetHeight;
    const topMargin = 10;
    const scrollValue = anchorElement.getBoundingClientRect().top + document.documentElement.scrollTop - topMargin - headerHeight;
    scrollTo(scrollValue);
  }
};

/**
 * Return the corresponding CSS breakpoint
 * according to current viewport size
 * @returns {number}
 */
export const getCurrentBreakpoint = () => {
  if (window.innerWidth < BREAKPOINT_SMALL) {
    return BREAKPOINT_SMALL;
  }
  else if (window.innerWidth < BREAKPOINT_MEDIUM) {
    return BREAKPOINT_MEDIUM;
  }
  else if (window.innerWidth < BREAKPOINT_LARGE) {
    return BREAKPOINT_LARGE;
  }
  else if (window.innerWidth < BREAKPOINT_XLARGE) {
    return BREAKPOINT_XLARGE;
  }
  else {
    return BREAKPOINT_FULL;
  }
};

/**
 * Lock scrollbar
 */
export const lock = () => {
  const scrollPosition = window.pageYOffset;
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  document.body.style.top = `-${scrollPosition}px`;
};

/**
 * Unlock scrollbar
 */
export const unlock = () => {
  const scrollPosition = document.body.style.top;
  document.body.removeAttribute("style");
  window.scrollTo(0, Math.abs(parseInt(scrollPosition)));
};

/**
 * Helper to load script
 * @param url
 */
export const loadScript = url => {
  let script = document.createElement('script');
  const prior = document.getElementsByTagName('script')[0];
  script.async = true;
  script.defer = true;
  script.src = url;
  prior.parentNode.insertBefore(script, prior);
};

/**
 * Load Gmap
 */
export const getGoogleMaps = () => {
  return new Promise((resolve, reject) => {
    if (JS_MAP_API_KEY && !isBrowserOutdated()) {
      if (typeof google === 'object' && typeof google.maps === 'object') {
        resolve();
      } else {
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${JS_MAP_API_KEY}&callback=initMap`);
        window.initMap = () => {
          resolve();
        }
      }
    }
    else {
      reject('[Google Maps API Error] Please check browser and JS_MAP_API_KEY.');
    }
  });
};

/**
 * Get recaptcha
 */
export const getGoogleRecaptcha = () => {
  return new Promise((resolve, reject) => {
    if (typeof grecaptcha === 'object') {
      resolve();
    } else {
      loadScript(`https://www.google.com/recaptcha/api.js?onload=onReCaptchaReady&amp;render=explicit`);
      window.onReCaptchaReady = () => {
        window.isRecaptcha = true;
        resolve();
      }
    }
  });
};

/**
 * Get SVG Icon
 * @param name Icon name
 * @returns {string}
 */
export const getSVGIcon = name => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  svg.classList.add(`svg-icon`);
  svg.classList.add(`svg-icon--${name}`);
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/build/icons/sprite.svg#sprite-${name}`);
  svg.appendChild(use);

  return svg;
};
/**
 *
 * @param {'Su'|'Mo'|'Tu'|'We'|'Th'|'Fr'|'Sa'} day
 * @param {int} hours: (0..23)
 * @param {int} minutes: (0..50)
 * @param {[string]} range
 * @return {'close'|'open'|'soon-closed'|'undefined'}
 */
export const getStoreStatus = ({ day, hours, minutes, range }) => {
  const currentTime = hours * 60 + minutes;
  const currentDayIndex = WEEK.indexOf(day);

  for (const entry of range) {
    const [days, hours] = entry.split('=');

    // If there are no hours for the day, it means the store is closed
    if (!hours) {
      const dayRanges = days.includes('-') ? days.split('-') : [days];
      const startDay = WEEK.indexOf(dayRanges[0]);
      const endDay = dayRanges[1] ? WEEK.indexOf(dayRanges[1]) : startDay;

      if (currentDayIndex >= startDay && currentDayIndex <= endDay) {
        return 'close';
      }
      continue;
    }

    const timeRanges = hours.split(',');
    const dayRanges = days.includes('-') ? days.split('-') : [days];
    const startDay = WEEK.indexOf(dayRanges[0]);
    const endDay = dayRanges[1] ? WEEK.indexOf(dayRanges[1]) : startDay;

    if (currentDayIndex >= startDay && currentDayIndex <= endDay) {
      for (const timeRange of timeRanges) {
        const [start, end] = timeRange.split('-').map(time => {
          const [h, m] = time.split(':').map(Number);
          return h * 60 + m;
        });

        if (currentTime >= start && currentTime <= end) {
          if (end - currentTime <= 60) {
            return 'soon-closed';
          }
          return 'open';
        }
      }
    }
  }

  return 'close';
}
/*
export const getStoreStatus = (data) => {
  // create open slot
  const openSlot = {};
  //
  data.range.forEach((periods) => {
    const periodSet = periods.split('=');
    const daySet = periodSet[0].split('-');
    if (daySet.length > 1) {
      const daySet = periodSet[0].split('-');
      data.week.forEach((item, index) => {

      });

    } else {
      openSlot[daySet[0]] = periodSet[1]
    }
  });
  console.log(openSlot)
  // ['Mo-Th=07:30-12:00,13:30-17:00', 'Fr=07:30-12:00,13:30-16:00']
  // get the opened days
  // if there is an `-`, it's a range of day > return table with all opened days
  // esle return tne day
  console.log(data.day);
  console.log(data.hours);
  console.log(data.minutes);
  //console.log(data.replace("\\", ''));
  return 'undefined';
}
*/


