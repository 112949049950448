import Highway from '@refletdigital/highway';
import {init} from '../init';
import { isBrowserOutdated } from '../utils/utils';
import LogoFilter from '../components/LogoFilter'
import CarouselHp from '../components/CarouselHp';

class Home extends Highway.Renderer {
  onEnter() {
    init();

    const carouselHp = document.querySelector('#jumbotron.carousel-hp');
    this.carousel = carouselHp ? new CarouselHp(carouselHp) : null;

    const LogoFilterBlocks = document.getElementById('produits').querySelectorAll('.item.block-link');
    for (let item of LogoFilterBlocks) {
      if (!isBrowserOutdated()) {
        new LogoFilter(item);
      } else {
        item.querySelector('.item__logo').classList.remove('item__logo--hidden');
      }
    }

    const pushCatalogContainer = document.querySelector("#push-catalog");
    if (pushCatalogContainer) {
      const pushCatalogStyleContainer = document.head.querySelector("#hp-push-catalog-style");
      const { color } = pushCatalogContainer.dataset;
      if (color !== null) {
        const pushCatalogStyles = `#push-catalog .item__title { color: ${color} } #push-catalog .button--push-catalog { border-color: ${color}; } #push-catalog .button--push-catalog::before { background-color: ${color}; } #push-catalog .button--push-catalog:hover { color: ${color}; } #push-catalog .button--regular-light.button--push-catalog { color: ${color}; } #push-catalog .button--regular-light.button--push-catalog:hover { color: white }`;

        if (pushCatalogStyleContainer) {
          pushCatalogStyleContainer.innerHTML = pushCatalogStyles
        } else {
          document.head.insertAdjacentHTML('beforeend', `<style id="hp-push-catalog-style">${pushCatalogStyles}</style>`);
        }
      }
    }

    document.body.classList.add('is-home');
    this.bindUIActions();
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {
    document.body.classList.remove('is-home');
    this.carousel && this.carousel.destroy();
    const pushCatalogStyleContainer = document.head.querySelector("#hp-push-catalog-style");
    if (pushCatalogStyleContainer) {
      pushCatalogStyleContainer.remove();
    }
  }

  onTakeRDVClick() {
    dataLayer.push({'event': 'CTA Accès Page EA Prendre RDV'});
  }

  onConfig3dClick() {
    dataLayer.push({'event': 'CTA Accès Configurateur 3D'});
  }

  bindUIActions() {
    const supports = document.querySelectorAll('#supports .item');
    const config3d = supports[0];
    const takeRDV = supports[1];
    config3d.addEventListener('click', () => this.onConfig3dClick());
    takeRDV.addEventListener('click', () => this.onTakeRDVClick());
  }
}

export default Home;
