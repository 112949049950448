import { getViewportWidth } from "./utils/utils";
import { BREAKPOINT_LARGE } from "./utils/constants";

// Polyfills
import { polyfills } from './utils/polyfill';
import 'cross-fetch/polyfill';
import 'classlist-polyfill';
import 'intersection-observer';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
polyfills();

// Components
import Highway from '@refletdigital/highway';
import Footer from './components/Footer';
import Header from './components/Header';
import Menu from './components/Menu';
import Search from './components/Search';
import Rgpd from './components/Rgpd';
import Newsletter from "./components/Newsletter";

// Transition
import Default from "./transitions/Default";

// Renders
import Home from "./renderers/Home";
import Blog from "./renderers/Blog";
import Products from "./renderers/Products";
import Contact from "./renderers/Contact";
import Product from "./renderers/Product";
import Store from "./renderers/Store";
import Locator from "./renderers/Locator";
import Page from "./renderers/Page";
import Sitemap from "./renderers/Sitemap";
import Results from "./renderers/Results";

// Layout
new Footer();
new Header();
new Rgpd();
const search = new Search();
const menu = new Menu();

// Prevent Highway handling symfo debug links
let debugLinks = document.querySelectorAll('.sf-dump a');
debugLinks.forEach( link => {
    link.setAttribute('data-router-disabled', 1);
});

/**
 * Newsletter init
 * @type {Element}
 */
const newsletter = document.querySelector('#footer-newsletter');
new Newsletter(newsletter);

// Highway
export const H = new Highway.Core({
    renderers: {
        home: Home,
        blog: Blog,
        products: Products,
        product: Product,
        contact: Contact,
        store: Store,
        locator: Locator,
        page: Page,
        sitemap: Sitemap,
        results: Results
    },
    transitions: {
        default: Default
    }
});

// Handle active link during PJAX navigation
const menuItems = document.querySelectorAll('.menu__item');

H.on('NAVIGATE_OUT', ({ location }) => {
    menuItems.forEach(item => {
        if (item.classList.contains('menu__item--active')) {
            item.classList.remove('menu__item--active');
        }

        const pathname = new URL(item.querySelector('.menu__link').href).pathname;
        if (location.pathname.includes(pathname)) {
            item.classList.add('menu__item--active');
        }
    });
    menu.onNavigate();
    search.onNavigate();
});

// Update meta desc + meta robots during PJAX navigation
const metaDescription = document.querySelector('meta[name="description"]');
const metaRobots = document.querySelector('meta[name="robots"]');

H.on('NAVIGATE_END', ({ to }) => {
    const newMetaDescription = to.page.head.querySelector('meta[name="description"]');
    if (newMetaDescription && metaDescription) {
        metaDescription.content = newMetaDescription.content;
    }

    const newMetaRobots = to.page.head.querySelector('meta[name="robots"]');
    if (newMetaRobots && metaRobots) {
        metaRobots.content = newMetaRobots.content;
    }

    detachMobileMenuItem(H);
});

// Disable Highway on mobile menu links
const detachMobileMenuItem = (H) => {
    const links = [];
    menuItems.forEach(item => {
        if (item.classList.contains('menu__item--with-submenu')) {
            links.push(item.querySelector('a'));
        }
    });

    if (getViewportWidth() < BREAKPOINT_LARGE) {
        H.detach(links);
    }

    window.addEventListener('resize', () => {
        if (getViewportWidth() >= BREAKPOINT_LARGE) {
            H.attach(links);
        } else {
            H.detach(links);
        }
    });
};

detachMobileMenuItem(H);

