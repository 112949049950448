import Highway from '@refletdigital/highway';
import Parallax from "../utils/parallax";
import {getCurrentBreakpoint, getSVGIcon, scrollToPromise} from "../utils/utils";
import {
  BREAKPOINT_FULL,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
  BREAKPOINT_XLARGE, EASE_DEFAULT
} from "../utils/constants";
import {TimelineLite} from "gsap";
import {init} from '../init';

class Products extends Highway.Renderer {
  onEnter() {
    init();

    this.el = document.querySelector('.products');
    //this.parallax = new Parallax(this.getItemsToParallax(), 0.05);
    this.itemsExtra = document.querySelectorAll('.item--extra');
    this.extraWrapper = document.querySelector('.extra');
    this.extraWrapper.appendChild(this.itemsExtra[0].cloneNode(true));
    this.extraBtn = this.extraWrapper.querySelector('.button');
    this.modal = null;
    this.isModalOpen = false;

    this.header = document.querySelector('.header');
    this.padding = 0;

    this.bindUIActions();
  }

  onLeave() {
    this.isModalOpen && this.removeModal();
  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {

  }

  getHeaderOffset() {
    return this.header.getBoundingClientRect().bottom;
  }

  /**
   * Get items to parallax by viewport
   * @returns Array of items
   */
  getItemsToParallax() {
    switch (getCurrentBreakpoint()) {
      case BREAKPOINT_FULL :
      case BREAKPOINT_XLARGE :
        return this.el.querySelectorAll('.item:nth-child(3n - 1)');
      case BREAKPOINT_LARGE :
      case BREAKPOINT_MEDIUM :
      case BREAKPOINT_SMALL :
        return [];
      default :
        return [];
    }
  }

  /**
   * Update parallax on resize
   */
  onResize() {
    //this.parallax.clear();
    //this.parallax.fill(this.getItemsToParallax());
    this.modal && this.removeModal();
  }

  /**
   * Event handler for escape button
   */
  onEscapeKeyDown(e) {
    if (e.key === 'Escape' && this.isModalOpen) {
       this.reverse();
    }
  }

  /**
   * Handle click on items extra
   * @param e
   */
  onItemExtraClick(e) {
    const { currentTarget } = e;
    e.preventDefault();

    this.showModal(currentTarget)
  }

  /**
   * Modal controller
   * @param rawContent Obj to duplicate
   */
  showModal(rawContent) {

    this.lock();
    this.padding = parseInt(window.getComputedStyle(rawContent).paddingLeft);
    const posY = rawContent.getBoundingClientRect().top + document.documentElement.scrollTop - this.getHeaderOffset() + this.padding;

    scrollToPromise(posY).then(() => {
        // Clear if modal exist, append if not exist
        this.modal ? this.clearModal() : this.appendModal();

        // Copy the content to display
        this.content = rawContent.cloneNode(true);
        this.content.appendChild(getSVGIcon('close'));

        // Copy attributes
        this.content.style.width = `${rawContent.offsetWidth}px`;
        this.content.style.height = `${rawContent.offsetHeight}px`;
        this.content.style.left = `${rawContent.getBoundingClientRect().left}px`;
        rawContent.getBoundingClientRect().left >= window.innerWidth / 2  && this.content.classList.add('item--right');

        // Append content
        this.appendContent(this.content);

        // Play animation
        this.play();
      }
    );
  }

  /**
   * Build modal container
   */
  appendModal() {
    this.modal = document.createElement('div');
    this.modal.classList.add('products-modal');
    this.modal.style.opacity = 0;
    this.modal.style.visibility = 'hidden';
    this.modal.style.top = `${this.getHeaderOffset() - this.padding}px`;
    this.modal.style.height = `calc(100vh - ${this.getHeaderOffset() - this.padding}px)`;

    document.body.appendChild(this.modal);

    this.modal.addEventListener('click', (e) => this.onModalClick(e));
  }

  /**
   * Add content to modal
   * @param content
   */
  appendContent(content) {
    this.modal.appendChild(content);
  }

  /**
   * Clear modal content
   */
  clearModal() {
    this.modal.innerHTML = '';
  }

  /**
   * Play modal animation
   */
  play() {
    const text = this.content.querySelector('.item__text');
    const button = this.content.querySelector('.item__cta');
    const extraWrapper = this.content.querySelector('.item__extra-wrapper');
    const wrapper = this.content.querySelector('.item__wrapper');
    this.timeline = new TimelineLite(
      {
        onReverseComplete: () => this.onReverseComplete(),
        onComplete: () => this.onComplete()
      });

    this.timeline.set(wrapper, {height: '100%', overflow: 'hidden'});
    this.timeline.to(this.modal, 0.25, {
      opacity: 1,
      visibility: 'visible',
      ease: EASE_DEFAULT
    });
    this.timeline.to(button, 0.25, {
      opacity: 0,
      ease: EASE_DEFAULT
    }, '-=0.25');
    this.timeline.to(extraWrapper, 0.35, {
      top: 0,
      y: 0,
      height: '100%'
    });
    this.timeline.to(this.content, 0.35, {
      height: `${window.innerHeight - this.getHeaderOffset() + (this.padding * 2)}px`,
      ease: EASE_DEFAULT
    }, '-=0.35');
    this.timeline.to(text, 0.35, {
      height: `${text.scrollHeight}px`,
      ease: EASE_DEFAULT,
      onComplete: () => TweenLite.set(text, {overflow: 'visible'})
    }, '-=0.35');

    this.isModalOpen = true;
  }

  /**
   * Reverse modal animation
   */
  reverse() {
    const wrapper = this.content.querySelector('.item__wrapper');
    wrapper.style.overflow = 'hidden';
    this.timeline.reverse();
  }

  /**
   * Quickly hide modal
   * Used if user leave page
   */
  removeModal() {
    TweenLite.to(this.modal, 0.25, {
      opacity: 0,
      ease: EASE_DEFAULT,
      onComplete: () => {
        this.modal.remove();
        this.modal = null;
        this.unlock();
      }
    });
  }

  /**
   * Callback after animation
   */
  onComplete() {
    const wrapper = this.content.querySelector('.item__wrapper');
    wrapper.style.overflow = 'auto';
  }

  /**
   * Callback after reverse animation
   */
  onReverseComplete() {
    this.unlock();
    this.modal.style.visibility = 'hidden';
    this.isModalOpen = false;
  }

  /**
   * Close modal if user click outside
   * @param e
   */
  onModalClick(e) {
    const { classList } = e.target;
    if (classList.contains('products-modal') || classList.contains('svg-icon')) {
      this.reverse();
    }
  }

  onExtraBtnClick() {

    this.extraText = this.extraWrapper.querySelector('.item__text');
    TweenLite.to(this.extraText, 0.25, {
      height: this.extraText.scrollHeight,
      ease: EASE_DEFAULT,
      onComplete: () => {
        this.extraBtn.remove();
      }
    });
  }

  /**
   * Custom unlock function
   * Do not use project unlock function
   */
  lock() {
    document.body.style.overflow = 'hidden';
  }

  /**
   * Custom unlock function
   * Do not use project unlock function
   */
  unlock() {
    document.body.style.overflow = 'auto';
  }

  /**
   * Handle on resize
   */
  bindUIActions() {
    window.addEventListener('resize', () => this.onResize());
    window.addEventListener('keydown', (e) => this.onEscapeKeyDown(e));

    this.itemsExtra.forEach( item => {
        if (item.querySelector('.item__text')) {
          item.addEventListener('click', (e) => this.onItemExtraClick(e))
        }
      }
    );

    this.extraBtn && this.extraBtn.addEventListener('click', () => this.onExtraBtnClick());
  }
}

export default Products;
