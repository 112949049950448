import Highway from '@refletdigital/highway';
import {init} from '../init';
import {
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL, CLOSE_MENU, CLOSE_SEARCH_RESULTS,
  COLOR_BLUE,
  COLOR_GRAY_DARK,
  DURATION_MEDIUM,
  DURATION_SLOW,
  EASE_DEFAULT, OPEN_MENU, OPEN_SEARCH_RESULTS,
  PIN_PATH
} from "../utils/constants";
import {getCurrentBreakpoint, getGoogleMaps, scrollToAnchor, getStoreStatus} from "../utils/utils";
import {WEEK} from "../utils/constants";
import {TimelineLite, TweenLite} from "gsap";
import phoneModal from "../components/PhoneModal";
import { H } from '../app';
import DataLink from "../components/DataLink";

class Locator extends Highway.Renderer {
  onEnter() {
    init();
    this.now = new Date();
    this.storesEl = document.querySelectorAll('.locator__list .item .status');
    this.storesEl.forEach((store) => {
      store.className = `status status--${getStoreStatus({
        day: WEEK[this.now.getDay()],
        hours: this.now.getHours(),
        minutes: this.now.getMinutes(),
        range: JSON.parse(store.dataset.timetable.replaceAll("'",'"')),
      })}`;
    });
    this.el = [...document.querySelectorAll('.locator:last-child')].pop();
    this.locatorType = this.el.dataset.locator;
    this.map = null;
    this.markers = [];
    this.list = this.el.querySelector('#list');
    this.goToListBtn = this.el.querySelector('#to-list');
    this.goToLocatorBtn = document.querySelector('#to-locator');
    this.toggleFiltersBtn = this.el.querySelector('#toggle-filters');
    this.currentInfowindow = null;
    this.tabs = this.el.querySelectorAll('.locator-tab__item');
    this.filtersWrapper = this.el.querySelector('.map-filters');
    this.filtersForm = this.el.querySelector('.map-filters__form');
    this.mobileInfowindow = this.el.querySelector('.map-infowindow');
    this.mobileInfowindowInner = this.mobileInfowindow.querySelector('.map-infowindow__inner');
    this.inputs = this.filtersWrapper ? this.filtersWrapper.querySelectorAll('input') : [];
    this.links = this.filtersWrapper ? this.filtersWrapper.querySelectorAll('a') : [];
    this.counter = this.el.querySelector('.map-filters__counter');
    this.filters = [];
    this.switcher = this.el.querySelector('.locator__switcher');
    this.switcherBtn = this.switcher.querySelectorAll('.switcher__button');
    this.currentSwitcher = this.el.querySelector('.switcher__item--active .switcher__button');
    this.isFiltersOpen = false;
    this.isMapOpen = false;
    this.isMobileInfowindowOpen = false;
    this.isSwitcherOpen = true;
    this.isDraging = false;
    this.initialPosY = 0;
    this.delta = 0;
    this.phones = this.el.querySelectorAll('.item__phone--mobile button');

    this.goToListBtn && H.detach([this.goToListBtn]);
    this.goToLocatorBtn && H.detach([this.goToLocatorBtn]);

    this.bindDataLayers();
    this.init();
  }

  onLeave() {

  }

  onEnterCompleted() {
    this.initMap();
    this.bindUIActions();
  }

  onLeaveCompleted() {

  }

  init() {
    let items = this.el.querySelectorAll('.locator__list .item');

    this.items = Array.from(items).reduce((acc, current) => {
      let exist = acc.filter( entry => entry.dataset.id === current.dataset.id);
      if(exist.length === 0) {
        acc.push(current);
      }
      return acc
    }, []);
  }

  /**
   * Init map
   */
  initMap() {
    getGoogleMaps().then(() => {
      const container = document.getElementById('map');
      TweenLite.set(container, {opacity: 0});

      this.map = new google.maps.Map(container, {
        center: {lat: 48.866667, lng: 2.333333},
        zoom: 6,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        mapTypeControl: false
      });

      this.items.length > 0 && this.buildMarkers();
      TweenLite.to(container, DURATION_SLOW, {opacity: 1, delay: 1, ease: EASE_DEFAULT});
    })
    .catch((error) => {
      console.log(error);
    })
    ;
  }

  /**
   * Build markers
   */
  buildMarkers() {
    let bounds = new google.maps.LatLngBounds();

    this.items.forEach( item => {

      const type = parseInt(item.dataset.type);
      const name = item.dataset.name;

      const icon = {
        path: PIN_PATH,
        fillColor: type === 0 ? COLOR_BLUE : COLOR_GRAY_DARK,
        fillOpacity: 1,
        scale: 0.075,
        strokeWeight: 0,
        anchor: new google.maps.Point(175,500),
      };

      const infowindowContent = document.createElement('div');
      infowindowContent.classList.add('item');
      infowindowContent.dataset.name = name;
      infowindowContent.innerHTML = item.innerHTML;

      const mobileInfowindowContent = infowindowContent.cloneNode(true);

      const infowindow = new google.maps.InfoWindow({
        content: infowindowContent
      });

      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(item.dataset.lat),
          lng: parseFloat(item.dataset.lng),
        },
        icon: icon,
        animation: google.maps.Animation.DROP,
        map: this.map,
        optimized: false
      });

      marker.addListener('mousedown', () => {
        if (getCurrentBreakpoint() === BREAKPOINT_MEDIUM || getCurrentBreakpoint() === BREAKPOINT_SMALL) {
          this.bindItemUIActions(mobileInfowindowContent);
          this.mobileInfowindowInner.innerHTML = '';
          this.mobileInfowindowInner.append(mobileInfowindowContent);
          !this.isMobileInfowindowOpen && this.openMobileInfowindow();
        } else {
          this.bindItemUIActions(infowindowContent);
          infowindow.open(this.map, marker);
          this.currentInfowindow && this.currentInfowindow.close();
          this.currentInfowindow = infowindow;
        }

        if (this.locatorType === 'crafter') {
          dataLayer.push({'event': 'Clic icon Map Pro', 'ArtisanName': name});
        }

        if (this.locatorType === 'store') {
          dataLayer.push({'event': 'Clic icon Map', 'ShowroomName': name});
        }
      });

      this.markers.push(marker);
      bounds.extend(marker.position);
    });

    this.map.fitBounds(bounds);
  }

  /**
   * Clear markers
   */
  clearMarkers() {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];
  }

  /**
   * Usefull for anchor
   * @param e
   */
  goTo(e) {
    const { target } = e;
    e.stopImmediatePropagation();
    e.preventDefault();
    scrollToAnchor(target);
  }

  /**
   * Fired on opening main menu
   */
  onMenuOpen() {
    this.closeSwitcher();
  }

  /**
   * Fired on closing main menu
   */
  onMenuClose() {
    this.openSwitcher();
  }

  /**
   * Fired on opening search results
   */
  onSearchResultsOpen() {
    this.closeSwitcher();
  }

  /**
   * Fired on closing search results
   */
  onSearchResultsClose() {
    this.openSwitcher();
  }

  /**
   * Show switcher
   */
  openSwitcher() {
    this.isSwitcherOpen = true;
    TweenLite.to(this.switcher, DURATION_MEDIUM, {y: '0%', opacity: 1, ease: EASE_DEFAULT});
  }

  /**
   * Hide switcher
   */
  closeSwitcher() {
    this.isSwitcherOpen = false;
    TweenLite.to(this.switcher, DURATION_MEDIUM, {y: '100%', opacity: 0, ease: EASE_DEFAULT});
  }

  /**
   * Toggle filters
   * Only used for mobile device
   * @param e
   */
  toggleFilters(e) {
    e.preventDefault();
    this.isFiltersOpen ? this.closeFilters() : this.openFilters();
  }

  /**
   * Open mobile filter
   * Only used for mobile device
   */
  openFilters() {
    this.isFiltersOpen = true;
    this.isMobileInfowindowOpen && this.closeMobileInfowindow();
    this.isSwitcherOpen && this.closeSwitcher();
    TweenLite.to(this.filtersWrapper, DURATION_MEDIUM, {y: '0%', ease: EASE_DEFAULT});
  }

  /**
   * Close mobile infowindow
   * Only used for mobile device
   */
  closeFilters() {
    this.isFiltersOpen = false;
    TweenLite.to(this.filtersWrapper, DURATION_MEDIUM, {y: '100%', ease: EASE_DEFAULT});
    !this.isSwitcherOpen && this.openSwitcher();
  }

  /**
   * Open mobile infowindow
   * Only used for mobile device
   */
  openMobileInfowindow() {
    this.isMobileInfowindowOpen = true;
    this.isFiltersOpen && this.closeFilters();
    this.isSwitcherOpen && this.closeSwitcher();
    TweenLite.to(this.mobileInfowindow, DURATION_MEDIUM, {y: '0%', ease: EASE_DEFAULT});
  }

  /**
   * Close mobile infowindow
   * Only used for mobile device
   */
  closeMobileInfowindow() {
    this.isMobileInfowindowOpen = false;
    TweenLite.to(this.mobileInfowindow, DURATION_MEDIUM, {y: '100%', ease: EASE_DEFAULT});
    !this.isSwitcherOpen && this.openSwitcher();
  }

  /**
   * On start drag mobile infowindow
   * Function must work for touchstart and movedown event
   * @param e
   */
  onStartDragMobileInfowindow(e) {
    this.isDraging = true;
    this.initialPosY = e.pageY ? e.pageY : e.touches[0].pageY;
  }

  /**
   * On end drag mobile infowindow
   * Function must work for touchend and moveup event
   * @param e
   */
  onEndDragMobileInfowindow(e) {
    this.isDraging = false;
    this.initialPosY = null;
    if (this.delta > 40) {
      this.closeMobileInfowindow();
    } else {
      this.openMobileInfowindow();
    }
  }

  /**
   * On drag mobile infowindow
   * Function must work for touchmove and mousemove event
   * @param e
   */
  onDragMobileInfowindow(e) {
    if (this.isDraging) {
      this.delta = (e.pageY ? e.pageY : e.touches[0].pageY) - this.initialPosY;
      if (this.delta > 0) {
        TweenLite.set(this.mobileInfowindow, {y: `${this.delta}px`, ease: EASE_DEFAULT});
      }
    }
  }

  /**
   * Open map for mobile device
   */
  openMap() {
    this.el.classList.add('locator--map');
    this.isMapOpen = true;
  }

  /**
   * Close map for mobile device
   */
  closeMap() {
    this.el.classList.remove('locator--map');
    this.isMapOpen = false;
  }

  /**
   * Handle mobile tab
   * @param e
   */
  onTabClick(e) {
    e.preventDefault();
    const { currentTarget, target } = e;
    const link = target.getAttribute('href');
    const section = this.el.querySelector(link);

    this.currentSection.classList.remove('locator__section--active');
    this.currentTab.classList.remove('locator-tab__item--active');

    section.classList.add('locator__section--active');
    currentTarget.classList.add('locator-tab__item--active');

    this.currentSection = section;
    this.currentTab = currentTarget;
  }

  /**
   * Open modal when user click on phone link
   * @param e
   */
  onPhoneClick(e) {
    e.preventDefault();
    const { target } = e;

    const item = target.closest('.item');
    const title = item.querySelector('.item__title').innerHTML;
    const phoneLinks = item.querySelectorAll('.item__phone a');
    const phones = [];

    phoneLinks.forEach(phoneLink => {
      phones.push({
        'link': phoneLink.href,
        'content': phoneLink.querySelector('.button__label').innerHTML.replace(':', '')
      })
    });

    phoneModal.setContent(title, phones);
    phoneModal.open();
  }

  /**
   * Toggle map / list on switcher click
   * @param e
   */
  onSwitcherClick(e) {
    const { target } = e;
    const type = target.dataset.type;

    this.currentSwitcher.parentNode.classList.remove('switcher__item--active');
    target.parentNode.classList.add('switcher__item--active');

    type === 'map' && this.openMap();
    type === 'list' && this.closeMap();

    this.currentSwitcher = target;
  }

  /**
   * On resize
   */
  onResize() {
    if (this.filtersWrapper) {
      TweenLite.set(this.filtersWrapper, {y: '0%'});
      this.isFiltersOpen = true;
    }
  }

  /**
   * Handle input click
   * @param e
   */
  onInputClick(e) {
    const { name, id, value, checked }  = e.target;
    this.updateFilters(name, value !== '' ? value : id, checked);
  }

  /**
   * Handle link click
   * @param e
   */
  onLinkClick(e) {
    const { target }  = e;
    const { href }  = target;
    e.preventDefault();

    this.links.forEach(
      item => {
        item.classList.remove('form__link--active');
      }
    );
    target.classList.add('form__link--active');

    this.fetch(href);
  }

  /**
   * On back button click
   * @param e
   */
  onBackBtnClick(e) {
    e.preventDefault();
    this.links[0].click();
  }

  /**
   * Update filter state
   * @param name of input
   * @param id of input
   * @param checked
   */
  updateFilters(name, id, checked) {
    const newFilter = {
      key: name,
      value: id
    };

    this.filters = this.filters.filter(filter => (filter.key !== newFilter.key));
    checked && this.filters.push(newFilter);

    this.fetch();
  }

  /**
   * Build query params
   * @returns {string}
   */
  getParams() {
    const params = new URLSearchParams();
    this.filters.forEach(filter => params.set(filter.key, filter.value));
    return params.toString() !== '' ? '?' + params.toString() : '';
  }

  /**
   * Fetch same page with current filters
   * @param url to fetch. If empty we take the current URL
   */
  fetch(url = location.protocol + '//' + location.host + location.pathname) {
    this.lock();
    fetch(url + this.getParams(), {
      method: 'get'
    })
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
      throw new Error('Network error during fetch');
    })
    .then(text => {
      this.setHistory(url + this.getParams());

      const parser = new DOMParser();
      const htmlDocument = parser.parseFromString(text, 'text/html');
      const list = htmlDocument.documentElement.querySelector('#list');
      const locator = htmlDocument.documentElement.querySelector('.locator');
      const filtersWrapper = htmlDocument.documentElement.querySelector('.map-filters');

      let tween = new TimelineLite();

      tween.to(this.list, 0.5, {
        opacity: 0,
        ease: EASE_DEFAULT,
        onComplete: () => {
          this.list.innerHTML = list.innerHTML;
          this.filtersWrapper.innerHTML = filtersWrapper.innerHTML;
          this.inputs = this.filtersWrapper.querySelectorAll('input');
          this.links = this.filtersWrapper.querySelectorAll('a');
          this.phones = this.el.querySelectorAll('.item__phone--mobile button');
          this.el.setAttribute('class', locator.getAttribute('class'));
          this.isMapOpen && this.el.classList.add('locator--map');
          if ((getCurrentBreakpoint() === BREAKPOINT_MEDIUM || getCurrentBreakpoint() === BREAKPOINT_SMALL) && this.isFiltersOpen) {
             this.closeFilters();
          }
          this.unlock();
          this.init();
          this.map && this.clearMarkers();
          this.map && this.buildMarkers();
          this.bindTabsActions();
          this.bindFiltersActions();
          this.bindPhonesActions();
          this.bindDataLayers();
          TweenLite.set(this.list, {y: 20})
        }
      });
      tween.to(this.list, 0.5, {
        opacity: 1,
        y: 0,
        ease: EASE_DEFAULT,
      });

      this.goToListBtn && H.detach([this.goToListBtn]);
      this.goToLocatorBtn && H.detach([this.goToLocatorBtn]);
    })
    .catch((error) => {
      console.error(error.message);
    });
  }

  /**
   * Set history for sharable link
   * @param url
   */
  setHistory(url) {
    history.pushState({page: url}, null, url);
  }

  /**
   * Lock scroll
   */
  lock() {
    this.filtersForm.style.pointerEvents = 'none';
    TweenLite.to(this.filtersForm, DURATION_MEDIUM, {opacity: 0.2, ease: EASE_DEFAULT});
  }

  /**
   * Unlock scroll
   */
  unlock() {
    this.filtersForm.style.pointerEvents = 'auto';
    TweenLite.to(this.filtersForm, DURATION_MEDIUM, {opacity: 1, ease: EASE_DEFAULT});
  }

  /**
   * Handle mobile tabs
   */
  bindTabsActions() {
    this.tabs = this.el.querySelectorAll('.locator-tab__item');
    this.currentSection = this.el.querySelector('.locator__section');
    this.currentTab = this.el.querySelector('.locator-tab__item');
    const backBtn = this.el.querySelector('.locator__back');

    this.tabs.forEach(
      item => {
        item.addEventListener('click', (e) => this.onTabClick(e));
      }
    );

    backBtn && backBtn.addEventListener('click', (e) => this.onBackBtnClick(e));
  }

  /**
   * On filters wrapper click
   */
  onFiltersWrapperClick() {
    if ((getCurrentBreakpoint() === BREAKPOINT_MEDIUM || getCurrentBreakpoint() === BREAKPOINT_SMALL) && this.isFiltersOpen) {
      this.closeFilters();
    }
  }

  /**
   * Bind filters
   * Used for first paint and ajax update
   */
  bindFiltersActions() {
    this.inputs.forEach(
      item => {
        item.addEventListener('click', (e) => this.onInputClick(e));
      }
    );

    this.links.forEach(
      item => {
        item.addEventListener('click', (e) => this.onLinkClick(e));
      }
    );
  }

  /**
   * Bind phones
   * Used for first paint and ajax update
   */
  bindPhonesActions() {
    this.phones.forEach(
      item => {
        item.addEventListener('click', (e) => this.onPhoneClick(e));
      }
    );
  }

  /**
   * On RDV Button click
   * @param e
   */
  onRdvButtonClick(e) {
    const { currentTarget } = e;
    const item = currentTarget.closest('.item');
    const isList = item.parentElement.classList.contains('locator__list');
    const name = item.dataset.name;
    dataLayer.push({'event': isList ? 'CTA RDV' : 'Clic icon RDV', 'NomVille': name});
  }

  /**
   * On Discover Button click
   * @param e
   */
  onDiscoverButtonClick(e) {
    const { currentTarget } = e;
    const item = currentTarget.closest('.item');
    const isList = item.parentElement.classList.contains('locator__list');
    const name = item.dataset.name;

    if (this.locatorType === 'crafter') {
      dataLayer.push({'event': `CTA Voir Artisan${isList ? '' : ' Map'} Pro`, 'ArtisanName': name});
    }

    if (this.locatorType === 'store') {
      dataLayer.push({'event': `Clic Salle Expo${isList ? '' : ' Map'}`, 'ShowroomName': name});
    }
  }

  /**
   * Bind Data layer
   */
  bindDataLayers() {
    const rdvButtons = this.list.querySelectorAll('.item__rdv');

    rdvButtons && rdvButtons.forEach(
      item => {
        item.addEventListener('click', (e) => this.onRdvButtonClick(e));
      }
    );

    const discoverButtons = this.list.querySelectorAll('.item__discover');

    discoverButtons && discoverButtons.forEach(
      item => {
        item.addEventListener('click', (e) => this.onDiscoverButtonClick(e));
      }
    );
  }

  /**
   * Bind Item
   * Used for map infowindow
   * @param item
   */
  bindItemUIActions(item) {
    const links = item.querySelectorAll('a:not([target="_blank"])');
    H.attach(links);

    const dataLinks = item.querySelectorAll('.data-link');
    dataLinks && dataLinks.forEach(
      dataLink => {
        new DataLink(dataLink);
      }
    );

    this.phones = item.querySelectorAll('.item__phone--mobile button');
    this.phones.forEach(
      item => {
        item.addEventListener('click', (e) => this.onPhoneClick(e));
      }
    );

    const discoverBtn = item.querySelector('.item__discover .button');
    discoverBtn && discoverBtn.addEventListener('click', (e) => this.onDiscoverButtonClick(e));

    const rdvBtn = item.querySelector('.item__rdv .button');
    rdvBtn && rdvBtn.addEventListener('click', (e) => this.onRdvButtonClick(e));
  }

  bindUIActions() {
    this.goToListBtn && this.goToListBtn.addEventListener('click', (e) => this.goTo(e));
    this.goToLocatorBtn && this.goToLocatorBtn.addEventListener('click', (e) => this.goTo(e));
    this.filtersWrapper && this.toggleFiltersBtn.addEventListener('click', (e) => this.toggleFilters(e));
    this.filtersWrapper && this.filtersWrapper.addEventListener('click', (e) => this.onFiltersWrapperClick(e));
    this.mobileInfowindow.addEventListener('mouseup', (e) => this.onEndDragMobileInfowindow(e));
    this.mobileInfowindow.addEventListener('touchend', (e) => this.onEndDragMobileInfowindow(e));
    this.mobileInfowindow.addEventListener('mousedown', (e) => this.onStartDragMobileInfowindow(e));
    this.mobileInfowindow.addEventListener('touchstart', (e) => this.onStartDragMobileInfowindow(e));
    this.mobileInfowindow.addEventListener('mousemove', (e) => this.onDragMobileInfowindow(e));
    this.mobileInfowindow.addEventListener('touchmove', (e) => this.onDragMobileInfowindow(e));
    document.addEventListener(OPEN_MENU, () => this.onMenuOpen());
    document.addEventListener(CLOSE_MENU, () => this.onMenuClose());
    document.addEventListener(OPEN_SEARCH_RESULTS, () => this.onSearchResultsOpen());
    document.addEventListener(CLOSE_SEARCH_RESULTS, () => this.onSearchResultsClose());

    this.bindTabsActions();
    this.bindFiltersActions();
    this.bindPhonesActions();

    this.switcherBtn.forEach(
      item => {
        item.addEventListener('click', (e) => this.onSwitcherClick(e));
      }
    );

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Locator;
