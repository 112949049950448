import { TweenLite } from 'gsap';
import {EASE_DEFAULT, DURATION_FAST} from "../utils/constants";

class Accordion {
  constructor(el) {
    this.el = el;
    this.content = el.querySelector('.accordion__content');
    this.toggleButton = el.querySelector('.accordion__toggle');
    this.isOpened = false;
    this.isNavigation = el.classList.contains('accordion--navigation');
    this.bindUIActions();
  }

  /**
   * Add event listener on toggleButton
   */
  bindUIActions() {
    this.toggleButton.addEventListener('click', (e) => this.toggle(e));
  }

  /**
   * toggle accordion
   */
  toggle() {
    if (this.isOpened) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * open content with animation
   */
  open() {
    TweenLite.to(this.content, DURATION_FAST, {maxHeight: this.content.scrollHeight, ease: EASE_DEFAULT, onComplete: () => {
        this.el.classList.add('accordion--open');
        // This prevent height pb with absolute position for navigation (project sdb)
        if (!this.isNavigation) {
          this.content.style.maxHeight = '100%';
        }
        console.log(this.isNavigation);
        console.log(this.el);
        this.isOpened = true;
      }});
  }

  /**
   * close content with animation
   */
  close() {
    this.content.style.maxHeight = this.content.scrollHeight + 'px';
    TweenLite.to(this.content, DURATION_FAST, {maxHeight: 0, ease: EASE_DEFAULT, onComplete: () => {
        this.el.classList.remove('accordion--open');
        this.isOpened = false;
      }});
  }
}

export default Accordion;
