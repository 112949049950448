class SharingLinks {

  constructor(el) {
    this.el = el;
    this.text = document.title.replaceAll(/\|/g, "%7C");
    this.url = window.location.href;
    this.image = this.getImage();

    this.bindUIActions();
  }

  getImage() {
    let url = '';
    const image = document.querySelector('.article__image');
    if (image) {
      url = window.location.origin + image.getAttribute('src');
    }

    return url;
  }

  /**
   * Data layer
   * @param e
   */
  shareX(e){
    e.preventDefault();
    open('https://x.com/intent/tweet?text=' + this.text + '&url=' + this.url, 'tshare', 'height=400,width=550,resizable=1,toolbar=0,menubar=0,status=0,location=0');
    dataLayer.push({'event': 'Partage Twitter'});
  }

  /**
   * Data layer
   * @param e
   */
  shareFacebook(e) {
    e.preventDefault();
    open('https://www.facebook.com/sharer/sharer.php?u=' + this.url, 'fbshare', 'height=380,width=660,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0');
    dataLayer.push({'event': 'Partage Facebook'});
  }

  /**
   * Data layer
   * @param e
   */
  sharePinterest(e) {
    e.preventDefault();
    open('https://pinterest.com/pin/create/button/?url=' + this.url + '&media=' + this.image + '&description=' + this.text, 'ptshare', 'height=400,width=550,resizable=1,toolbar=0,menubar=0,status=0,location=0');
    dataLayer.push({'event': 'Partage Pinterest'});
  }

  /**
   * Data layer
   * @param e
   */
  shareEmail(e) {
    e.preventDefault();
    open('mailto:?subject=' + this.text + '&body=' + this.url);
    dataLayer.push({'event': 'Partage Email'});
  }

  /**
   * Bind UI Actions
   */
  bindUIActions() {
    const facebookItem = this.el.querySelector('[data-type="facebook"]');
    const xItem = this.el.querySelector('[data-type="x"]');
    const pinterestItem = this.el.querySelector('[data-type="pinterest"]');
    const emailItem = this.el.querySelector('[data-type="email"]');

    facebookItem.addEventListener('click', (e) => this.shareFacebook(e));
    xItem.addEventListener('click', (e) => this.shareX(e));
    pinterestItem.addEventListener('click', (e) => this.sharePinterest(e));
    emailItem.addEventListener('click', (e) => this.shareEmail(e));
  }
}

export default SharingLinks;
