import {Power2} from 'gsap';
import styles from './../../scss/abstracts/_variables.scss?module';
/**
 * Easing
 */
export const EASE_DEFAULT = Power2.easeInOut;
export const EASE_OUT = Expo.easeOut;

/**
 * Duration
 */
export const DURATION_FAST = styles.durationFast ? styles.durationFast.split('s')[0] : .15;
export const DURATION_MEDIUM = styles.durationFast ?styles.durationMedium.split('s')[0] : .3;
export const DURATION_SLOW = styles.durationMedium ? styles.durationMedium.split('s')[0] : .6;

/**
 * Breakpoint
 */
export const BREAKPOINT_FULL = styles.breakpointFull ? styles.breakpointFull.split('px')[0] : 1350;
export const BREAKPOINT_XLARGE = styles.breakpointXlarge ? styles.breakpointXlarge.split('px')[0] : 1280;
export const BREAKPOINT_LARGE = styles.breakpointLarge ? styles.breakpointLarge.split('px')[0] : 1024;
export const BREAKPOINT_MEDIUM = styles.breakpointMedium ? styles.breakpointMedium.split('px')[0] : 768;
export const BREAKPOINT_SMALL = styles.breakpointSmall ? styles.breakpointSmall.split('px')[0] : 640;

/**
 * SVG Path
 */
export const PIN_PATH = 'M379.4,57.1C346.6,24.2,302.8,6,256.3,6S166,24.2,132.2,57.1c-61.1,61.1-68.4,176.1-16.4,246.4L256.3,506 l139.6-202.6C448.8,233.2,440.6,118.2,379.4,57.1z M258.1,243.2c-35.6,0-63.9-28.3-63.9-63.9s28.3-63.9,63.9-63.9 s63.9,28.3,63.9,63.9S292.8,243.2,258.1,243.2z';

/**
 * Color
 */
export const COLOR_BLUE = styles.colorPrimary;
export const COLOR_GRAY_DARK = styles.colorGrayDark;

/**
 * EVENTS
 */
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_SEARCH_RESULTS = 'OPEN_SEARCH_RESULTS';
export const CLOSE_SEARCH_RESULTS = 'CLOSE_SEARCH_RESULTS';
/**
 * DAYS OF THE WEEK
 */
export const WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const STATUS = {
  close: 'Fermé',
  open: 'Ouvert',
  'soon-closed': 'Bientôt fermé'
}

