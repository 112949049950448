import Highway from '@refletdigital/highway';
import Gallerie from "../components/Gallerie";
import Tabs from "../components/Tabs";
import {COLOR_BLUE, PIN_PATH, WEEK, STATUS} from "../utils/constants";
import {getGoogleMaps, getStoreStatus} from "../utils/utils";
import modal from '../components/Modal';
import {init} from '../init';
import ShiftedContent from "../components/ShiftedContent";

class Store extends Highway.Renderer {
  onEnter() {
    init();
    this.now = new Date();
    this.statusEl = document.querySelector('.page__header .status');
    this.status = getStoreStatus({
      day: WEEK[this.now.getDay()],
      hours: this.now.getHours(),
      minutes: this.now.getMinutes(),
      range: JSON.parse(this.statusEl.dataset.timetable.replaceAll("'",'"')),
    });
    this.statusEl.className = `status status--${this.status ?? 'undefined'}`;
    this.statusEl.textContent = STATUS[this.status] ?? '';

    this.el = document.querySelector('.store');
    dataLayer.push({
      showroomName: this.el.getAttribute('data-name')
    });
    this.map = null;
    this.mapContainer = this.el.querySelector('.store-map');
    this.mapButtons = document.querySelectorAll('.open-map');
    this.streetView = null;
    this.streetViewContainer = this.el.querySelector('.store-streetview');
    this.streetViewButtons = document.querySelectorAll('.open-streetview');

    const tabs = document.querySelectorAll('.tabs');
    tabs.forEach(
      el => {
        new Tabs(el);
      }
    );

    const gallerie = document.querySelector('.gallerie');
    const toFitWith = document.querySelector('.store__content');
    this.gallerie = gallerie ? new Gallerie(gallerie, toFitWith) : null;

    const shiftedContent = document.querySelector('.contents--shift');
    this.shiftedContent = shiftedContent ? new ShiftedContent(shiftedContent) : null;

    this.bindUIActions();
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {
    this.gallerie && this.gallerie.destroy();
    this.shiftedContent && this.shiftedContent.destroy();
  }

  initMap() {
    const { lat, lng, title, address } = this.mapContainer.dataset;

    getGoogleMaps().then(() => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: parseFloat(lat), lng: parseFloat(lng)},
        zoom: 16,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        mapTypeControl: false
      });

      const icon = {
        path: PIN_PATH,
        fillColor: COLOR_BLUE,
        fillOpacity: 1,
        scale: 0.075,
        strokeWeight: 0
      };

      const infowindowContent = document.createElement('div');
      infowindowContent.classList.add('item');
      infowindowContent.innerHTML = `<strong>${title}</strong><br>${address}<br><a target="_blank" href="https://www.google.com/maps?daddr=${lat},${lng}">Calculez l'itineraire</a>`;

      const infowindow = new google.maps.InfoWindow({
        content: infowindowContent
      });

      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        icon: icon,
        map: this.map,
      });

      infowindow.open(this.map, marker);

      marker.addListener('click', () => {
        infowindow.open(this.map, marker);
      });
    });
  }

  onMapButtonClick(e) {
    e.preventDefault();
    this.map === null && this.initMap();
    modal.setContent(this.mapContainer);
    modal.open();
  }

  onStreetViewButtonClick(e) {
    e.preventDefault();
    modal.setContent(this.streetViewContainer);
    modal.open();
  }

  bindUIActions() {
    if (this.mapButtons.length > 0) {
      this.mapButtons.forEach(button => button.addEventListener('click', (e) => this.onMapButtonClick(e)));
    }
    if (this.streetViewButtons.length > 0) {
      this.streetViewButtons.forEach(button => button.addEventListener('click', (e) => this.onStreetViewButtonClick(e)));
    }
  }
}

export default Store;
