class LogoFilter {

  constructor(el) {
    this.el = el;
    this.logo = this.el.querySelector('.item__logo');
    this.picture = this.el.querySelector('.item__image .item__picture img');
    this.bounce = false;
    this.bounceRate = 500;
    this.canvas = document.createElement('canvas');

    this.bindUIActions();
  }

  debounceResize(e) {
    clearTimeout(this.bounce);
    this.bounce = setTimeout(() => this.onResize(), this.bounceRate);
  }

  async onResize() {
    if (this.logo.offsetParent !== null) {
      if (
        await this.isLoaded(this.picture)
        && await this.isLoaded(this.logo)
      ) {
        var lightness = this.getLightness();
        var src = this.logo.src.replace('-blanc.svg', '.svg');
        this.logo.src = lightness >= 0.65 ? src : src.replace('.svg', '-blanc.svg');
        if (
          this.logo.classList.contains('item__logo--hidden')
          && await this.isLoaded(this.logo)
        ) {
          this.logo.classList.remove('item__logo--hidden');
        }
      }
    }
  }

  async isLoaded(image) {
    return await this
      .load(image)
      .then(e => {
        return true;
      })
      .catch(e => {
        return false;
      })
    ;
  }

  load(image) {
    let node = image.cloneNode(true);
    return new Promise((resolve, reject) => {
      if (node.complete) {
        resolve(null);
      } else {
        node.addEventListener('load', resolve);
        node.addEventListener('error', reject);
      }
    });
  }

  getLightness() {
    this.canvas.width = this.picture.naturalWidth;
    this.canvas.height = this.picture.naturalHeight;

    var context = this
      .canvas
      .getContext('2d')
    ;
    context.drawImage(this.picture, 0, 0);

    var ratio = this.picture.naturalWidth / this.picture.width;
    var rect = {
      x: Math.floor(this.logo.offsetLeft * ratio),
      y: Math.floor((this.logo.offsetTop + this.logo.offsetParent.offsetTop) * ratio),
      w: Math.floor(this.logo.width * ratio),
      h: Math.floor(this.logo.height * ratio)
    }

    var data = context
      .getImageData(rect.x, rect.y, rect.w, rect.h)
      .data
    ;

    var lightness = [];
    for(var i = 0; i < data.length; i += 4) {
      var hsl = this.rgbToHsl({
        r: data[i],
        g: data[i + 1],
        b: data[i + 2]
      });
      lightness.push(hsl.l);
    }

    return lightness.reduce((a, v, i, src) => a + v) / lightness.length;
  }

  rgbToHsl(rgb) {
    for (var color in rgb) {
      rgb[color] /= 255;
    }

    var min = Math.min(rgb.r, rgb.g, rgb.b);
    var max = Math.max(rgb.r, rgb.g, rgb.b);
    var d = max - min;

    var l = Math.min(1, Math.max(0, (min + max) / 2));
    var s = Math.min(1, Math.max(0, min == max ? 0 : d / (l < 0.5 ? max + min : 2 - max - min)));
    switch (max) {
      case rgb.r:
        var h = (rgb.g - rgb.b) / d;
        break;
      case rgb.g:
        var h = 2 + (rgb.b - rgb.r) / d;
        break;
      case rgb.b:
      default:
        var h = 4 + (rgb.r - rgb.g) / d;
        break;
    }
    h = Math.round(h * 60);
    if (h < 0) {
      h += 360;
    }

    return {
      h: h,
      s: s,
      l: l
    };
}

  /**
   * Bind UI Actions
   */
  bindUIActions() {
    window.addEventListener('resize', e => this.debounceResize(e));
    window.dispatchEvent(new Event('resize'));
  }
}

export default LogoFilter;
