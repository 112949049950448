import { TweenLite } from 'gsap';
import {EASE_DEFAULT, DURATION_FAST, BREAKPOINT_MEDIUM} from "../utils/constants";

class Filters {
  constructor(el) {
    this.el = el;
    this.content = el.querySelector('.filters__list');
    this.links = el.querySelectorAll('.filters__link');
    this.title = el.querySelector('.filters__title');
    this.isOpen = false;
    this.bindUIActions();
  }

  toggle(e) {
    e.preventDefault();
    this.isOpen ? this.close() : this.open();
  }

  close() {
    this.content.style.maxHeight = this.content.scrollHeight + 'px';
    TweenLite.to(this.content, DURATION_FAST, {maxHeight: 0, ease: EASE_DEFAULT, onComplete: () => {
        this.el.classList.remove('filters--open');
        this.isOpen = false;
      }});
  }

  open() {
    TweenLite.to(this.content, DURATION_FAST, {maxHeight: this.content.scrollHeight, ease: EASE_DEFAULT, onComplete: () => {
        this.el.classList.add('filters--open');
        this.content.style.maxHeight = '100%';
        this.isOpen = true;
      }});
  }

  onResize() {
    if (window.innerWidth >= parseInt(BREAKPOINT_MEDIUM)) {
      this.content.style.maxHeight = 'none';
    } else {
      this.isOpen = false;
      this.el.classList.remove('filters--open');
      this.content.style.maxHeight = 0;
    }
  }

  onLinkClick(e) {
    if (window.innerWidth < parseInt(BREAKPOINT_MEDIUM)) {
      const { target } = e;
      const svg = this.title.querySelector('svg');
      this.title.innerHTML = target.innerHTML;
      this.title.appendChild(svg);
      this.close();
    }
  }

  bindUIActions() {
    this.title.addEventListener('click', (e) => this.toggle(e));

    this.links.forEach(
      item => {
        item.addEventListener('click', (e) => this.onLinkClick(e));
      }
    );

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Filters;
