class DataLink {
  constructor(el) {
    this.el = el;
    this.bindUIActions();
  }

  bindUIActions() {
    this.el.addEventListener('click', (e) => this.clickHandler(e));
  }

  /**
   * clickHandler
   * @param e
   */
  clickHandler(e) {
    const href = this.el.getAttribute('data-href');
    if (this.el.getAttribute('data-target') === '_blank' || e.ctrlKey || e.metaKey) {
      window.open(href, '_blank');
    } else {
      let link = this.el.querySelector('.item__link');
      link.click();
    }
  }
}

export default DataLink;
