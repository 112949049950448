import Highway from '@refletdigital/highway';
import { init } from '../init';
import ShiftedContent from "../components/ShiftedContent";

class Page extends Highway.Renderer {
  onEnter() {
    init();
    this.el = document.querySelector('.page');

    const shiftedContent = document.querySelector('.contents--shift');
    this.shiftedContent = shiftedContent ? new ShiftedContent(shiftedContent) : null;
    this.bindUIActions();
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {
    this.shiftedContent && this.shiftedContent.destroy();
  }

  onRdvClick() {
    dataLayer.push({'event': 'CTA Accès Page EA Prendre RDV'});
  }

  onItemClick(e) {
    const { currentTarget } = e;
    const btnLabel = currentTarget.querySelector('.button').innerHTML.trim().toLowerCase();
    if (btnLabel === 'estimer mes aides') {
      dataLayer.push({'event': 'CTA Accès Simulateur Aides Financieres'});
    }
    if (btnLabel === 'commencer la simulation') {
      dataLayer.push({'event': 'CTA Accès Simulateur Chauffage'});
    }
    if (btnLabel === 'accéder à ma demande de devis' || btnLabel === 'accéder à la demande de devis') {
      dataLayer.push({'event': 'CTA Accès Formulaire Demande Devis'});
    }
    if (btnLabel === 'être rappelé') {
      dataLayer.push({'event': 'CTA Être Rappelé'});
    }
    if (btnLabel === 'débuter mon plan 3d') {
      dataLayer.push({'event': 'CTA Configurateur 3D'});
    }
    if (btnLabel === 'prendre rendez-vous') {
      dataLayer.push({'event': 'CTA Prise RDV EA'});
    }
  }

  bindUIActions() {
    const rdv = this.el.querySelector('.rdv');
    if (rdv) {
      rdv.addEventListener('click', () => this.onRdvClick())
    }

    const items = this.el.querySelectorAll('.item');
    items.forEach(item => (
      item.addEventListener('click', (e) => this.onItemClick(e))
    ))
  }
}

export default Page;
