import { isBrowserOutdated, scrollToAnchor } from './utils/utils';
import { BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from "./utils/constants";
import Breadcrumb from './components/Breadcrumb';
import BlockLink from './components/Blocklink';
import DataLink from './components/DataLink';
import Accordion from "./components/Accordion";
import Filters from "./components/Filters";
import Advertising from "./components/Advertising";
import Scrolltop from "./components/ScrollTop";
import { tns } from 'tiny-slider/src/tiny-slider';

/**
 * Init function is fired at each page change
 */
export const init = () => {
  /**
   * Accordions init
   * @type {NodeListOf<Element>}
   */
  const accordions = document.querySelectorAll('.accordion');
  accordions.forEach(
    accordion => {
      new Accordion(accordion);
    }
  );

  /**
   * ScrollToLinks init
   * @type {NodeListOf<Element>}
   */
  const scrollToLinks = document.querySelectorAll('.scroll-to');
  scrollToLinks.forEach(
    scrollToLink => {
      scrollToLink.addEventListener('click', (e) => {
        e.preventDefault();
        scrollToAnchor(scrollToLink);
      })
    }
  );

  /**
   * Blog filters init
   * @type {Element}
   */
  const filters = document.querySelector('.filters');
  filters && new Filters(filters);

  /**
   * Breadcrumb init
   * @type {Element}
   */
  const breadcrumb = document.querySelector('.breadcrumb');
  breadcrumb && new Breadcrumb(breadcrumb);

  /**
   * Block links init
   */
  const blockLinks = document.querySelectorAll('.block-link');
  blockLinks.forEach(
    blockLink => {
      new BlockLink(blockLink);
    }
  );

  const dataLinks = document.querySelectorAll('.data-link');
  dataLinks.forEach(
    dataLink => {
      new DataLink(dataLink);
    }
  );

  /**
   * Inputs init
   */
  const inputs = document.querySelectorAll('input, textarea');
  for (let input of inputs) {
    input.addEventListener('keyup', () => ( input.setAttribute('data-filled', input.value === '' ? 0 : 1 )))
  }

  /**
   * Pub
   */
  const ads = document.querySelectorAll('.advertising');
  ads.forEach(
    el => {
      new Advertising(el);
    }
  );

  /**
   * Scroll top
   */
  const scrollTop = document.getElementById('scrolltop');
  scrollTop && new Scrolltop(scrollTop);

  /**
   * Ad
   */
  let ad = document.querySelectorAll('.advertising');
  ad.forEach(
    el => {
      const adList = el.querySelector('.advertising__list');
      const prevButton = el.querySelector('.advertising__prev');
      const nextButton = el.querySelector('.advertising__next');
      if (adList) {
        tns({
          container: adList,
          controls: prevButton && nextButton,
          nav: !prevButton && !nextButton,
          mode: 'gallery',
          items: 1,
          speed: 600,
          autoplay: true,
          autoplayTimeout: 3000,
          prevButton: prevButton && prevButton,
          nextButton: nextButton && nextButton,
        });
      }
    }
  );
};
