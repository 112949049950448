class Header {

  constructor() {
    this.el = document.querySelector('.header');

    this.bindUIActions();
  }

  bindUIActions() {

  }
}

export default Header;