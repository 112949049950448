import { TweenLite } from 'gsap';
import Cookies from 'js-cookie';

import {EASE_DEFAULT, DURATION_MEDIUM} from "../utils/constants";

class Rgpd {
  constructor() {
    this.storage = window.localStorage;
    // fix crash with old rgpd
    if (this.storage.getItem('rgpd') && this.storage.getItem('rgpd').includes('advertising')) {
      this.storage.removeItem('rgpd');
    }

    this.el = document.querySelector('.rgpd-modal');
    this.performanceCheckbox = this.el.querySelector('#performance-cookies');
    this.advertisingCheckbox = this.el.querySelector('#advertising-cookies');

    if (!this.storage.getItem('rgpd')) {
      // initialise cookie to prevent facebook pixel
      Cookies.set('analytics_storage','granted');
      Cookies.set('ad_storage','denied');
      Cookies.set('ad_user_data','denied');
      Cookies.set('ad_personalization','denied');
      this.saveButton = this.el.querySelector('#rgpd-save');
      this.acceptButtons = this.el.querySelectorAll('.rgpd-accept-all');
      this.rejectButton = this.el.querySelector('#rgpd-reject-all');
      this.toggleSettingsButtons = this.el.querySelectorAll('.rgpd-settings-toggle');
      this.bindUIActions();
    } else {
      this.removeFromDom(false);
      // save datalayer from local storage
      this.setDatalayer(JSON.parse(this.storage.getItem('rgpd')));
    }
  }

  /**
   * Add listener
   */
  bindUIActions() {
    // Add listener
    this.displayElement();
    this.saveButton.addEventListener('click', (e) => this.onSave(e));
    this.acceptButtons.forEach((el) => {
      el.addEventListener('click', (e) => {
        this.performanceCheckbox.checked = true;
        this.advertisingCheckbox.checked = true;
        this.onSave(e);
      });
    });
    this.rejectButton.addEventListener('click', (e) => {
      this.performanceCheckbox.checked = false;
      this.advertisingCheckbox.checked = false;
      this.onSave(e);
    });
    this.toggleSettingsButtons.forEach((el) => {
      el.addEventListener('click', (e) => {
        this.el.querySelectorAll('.rgpd-modal__content').forEach((content) => {
          if (content.classList.contains('hidden')) {
            content.classList.remove('hidden');
          } else {
            content.classList.add('hidden');
          }
        });
      });
    });
  }

  displayElement() {
    this.el.classList.add('modal-enter-done');
  }

  /**
   * On accept button cliqued
   * @param e
   */
  onSave(e) {
    e.preventDefault();
    // Update Storage
    this.saveChoices();
    this.removeFromDom();
  }

  /**
   * Save choices in data storage and dataLayer
   */
  saveChoices() {
    this.rgpd = {
      event: 'Cookie Consent',
      analytics_storage: 'granted',
      ad_storage: this.advertisingCheckbox.checked ? 'granted': 'denied',
      ad_user_data: this.advertisingCheckbox.checked ? 'granted': 'denied',
      ad_personalization: this.advertisingCheckbox.checked ? 'granted': 'denied',
    };
    this.storage.setItem('rgpd', JSON.stringify(this.rgpd));
    this.setDatalayer(this.rgpd);
  }

  /**
   * Remove RGPD block from the DOM
   */
  removeFromDom(animate = true) {
    if (animate) {
      TweenLite.to(this.el, DURATION_MEDIUM, {
        opacity: 0,
        ease: EASE_DEFAULT,
        onComplete: () => {
          this.el.remove();
        },
      });
    } else {
      this.el.remove();
    }
  }

  /**
   * Create and set dataLayer
   * @param rgpd
   */
  setDatalayer(rgpd) {
    // remove cookies
    Cookies.remove('analytics_storage');
    Cookies.remove('ad_storage');
    Cookies.remove('ad_user_data');
    Cookies.remove('ad_personalization');
    // Set cookies
    Cookies.set('analytics_storage', 'granted');
    Cookies.set('ad_storage', rgpd.ad_storage);
    Cookies.set('ad_user_data', rgpd.ad_user_data);
    Cookies.set('ad_personalization', rgpd.ad_personalization);
    window.dataLayer = window.dataLayer || {};
    dataLayer.push(rgpd);
  }
}

export default Rgpd;
