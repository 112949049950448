import Highway from '@refletdigital/highway';
import Gallerie from '../components/Gallerie';
import SharingLinks from '../components/SharingLinks';
import {init} from '../init';
import Quote from '../components/Quote';
import ShiftedContent from "../components/ShiftedContent";
import {getCurrentBreakpoint} from "../utils/utils";
import {EASE_DEFAULT} from "../utils/constants";

class Product extends Highway.Renderer {
  onEnter() {
    init();
    this.el = [...document.querySelectorAll('.product:last-child')].pop();
    if (document.getElementById('product-quote-container')) {
      this.quote = new Quote(document.getElementById('product-quote-container'));
    }
    this.isSdB = window.location.pathname.indexOf('salle-de-bains') > 0;
    this.isChauffage = window.location.pathname.indexOf('chauffage') > 0;

    const gallerie = this.el.querySelector('.gallerie');
    const toFitWith = this.el.querySelector('.product__content');
    this.gallerie = gallerie ? new Gallerie(gallerie, toFitWith) : null;
    this.thumbnail = document.querySelector('.page__picture img');
    this.picture = document.querySelector('.page__picture');
    this.sharingLinksBlock = document.querySelector('.sharing-links');
    new SharingLinks(this.sharingLinksBlock);

    const shiftedContent = document.querySelector('.contents--shift');
    this.shiftedContent = shiftedContent ? new ShiftedContent(shiftedContent) : null;

    const iconContent = document.querySelector('.contents--icon');
    this.iconContentLink = iconContent && iconContent.querySelectorAll('.item');
    this.bindUIActions();
  }

  onLeave() {
    this.thumbnail.removeEventListener('load', this.stickSharingLinks);
  }

  onEnterCompleted() {
    if (this.thumbnail.complete) {
      this.stickSharingLinks();
    } else {
      this.thumbnail.addEventListener('load', this.stickSharingLinks);
    }

    TweenLite.from(this.sharingLinksBlock, 0.25, {
      opacity: 0,
      ease: EASE_DEFAULT,
    });
  }

  onLeaveCompleted() {
    this.gallerie && this.gallerie.destroy();
    this.shiftedContent && this.shiftedContent.destroy();
  }

  stickSharingLinks() {
    if (!this.thumbnail) {
      this.thumbnail = document.querySelector('.page__picture img');
    }
    if (!this.sharingLinksBlock) {
      this.sharingLinksBlock = document.querySelector('.sharing-links');
    }
    const thumbnailBottom = this.thumbnail.getBoundingClientRect().bottom + document.documentElement.scrollTop;
    const sharingBlockTop = this.sharingLinksBlock.getBoundingClientRect().top + document.documentElement.scrollTop;
    const offset = thumbnailBottom - sharingBlockTop + 30;
    if (offset > 0 && getCurrentBreakpoint()) {
      this.sharingLinksBlock.style.paddingTop = `${offset}px`
    } else {
      this.sharingLinksBlock.style.paddingTop = `0px`
    }
  }

  onResize() {
    this.stickSharingLinks();
  }

  /**
   * Data layer
   */
  onIconContentLinkClick(e) {
    const { currentTarget } = e;
    const item = currentTarget.closest('.item');
    const label = item.querySelector('.button').textContent.trim();

    if (label.toLowerCase() === 'commencer ma simulation') {
      dataLayer.push({'event': 'Fiche Produit - CTA Simulateur Chauffage'});
    } else if (label.toLowerCase() === 'commencer mon projet') {
      this.isSdB && dataLayer.push({'event': 'Fiche Produit - CTA Projet SdB'});
      this.isChauffage && dataLayer.push({'event': 'Fiche Produit - CTA Projet Chauffage/Clim'});
    } else {
      dataLayer.push({'event': `CTA ${label}`});
    }
  }

  bindUIActions() {
    if (this.gallerie) {
      const thumbnail = document.querySelector('.page__picture');
      thumbnail.style.cursor = 'pointer';
      thumbnail.addEventListener('click', () => {this.gallerie.showSlider()})
    }

    if (this.iconContentLink) {
      for (let item of this.iconContentLink) {
        item.addEventListener('click', (e) => this.onIconContentLinkClick(e));
      }
    }

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Product;
