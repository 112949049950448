import { TweenLite } from 'gsap';
import { getSVGIcon, getViewportWidth, lock, unlock } from './../utils/utils';
import { BREAKPOINT_LARGE, CLOSE_MENU, EASE_DEFAULT, OPEN_MENU } from './../utils/constants';

class Menu {

  constructor() {
    this.el = document.querySelector('.header__menus');
    this.header = document.querySelector('.header');
    this.toggleBtn = document.getElementById('menu-toggle');
    this.itemsWithSubmenu = this.el.querySelectorAll('.menu__item--with-submenu');
    this.isOpen = false;
    this.current = false;
    this.closeSvg = getSVGIcon('close-nav');
    this.burgerSvg = this.toggleBtn.querySelector('.svg-icon--burger');
    this.toggleBtn.querySelector('.icon-links__svg').appendChild(this.closeSvg);
    this.closeSvg.style.display = 'none';
    this.iconLinks = document.querySelectorAll('.icon-links__link');
    this.windowWidth = window.innerWidth;

    this.setSpecificClass();
    this.bindUIActions();
  }

  /**
   * Toggle menu
   * @param e
   */
  toggleMenu(e) {
    e.preventDefault();
    if (this.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  /**
   * Open menu
   */
  openMenu() {
    TweenLite.to(this.el, 0.25, {x: '0%'});
    lock();

    this.isOpen = true;
    this.closeSvg.style.display = 'block';
    this.burgerSvg.style.display = 'none';

    const event = new Event(OPEN_MENU);
    document.dispatchEvent(event);
  }

  /**
   * Close menu
   */
  closeMenu() {
    TweenLite.to(this.el, 0.25, {x: '-100%'});
    unlock();

    this.isOpen = false;
    this.closeSvg.style.display = 'none';
    this.burgerSvg.style.display = 'block';

    const event = new Event(CLOSE_MENU);
    document.dispatchEvent(event);
  }

  /**
   * Handle requesting submenu
   * @param item
   */
  openSubmenu(item) {
    this.current && this.closeSubmenu(this.current);
    item.classList.add('menu__item--expanded');
    const submenu = item.querySelector('.menu__submenu');
    getViewportWidth() >= BREAKPOINT_LARGE ? this.showSubmenu(submenu) : this.showMobileSubmenu(submenu);
    this.current = item;
  }

  /**
   * Handle closing submenu
   * @param item
   */
  closeSubmenu(item) {
    item.classList.remove('menu__item--expanded');
    const submenu = item.querySelector('.menu__submenu');
    getViewportWidth() >= BREAKPOINT_LARGE ? this.hideSubmenu(submenu) : this.hideMobileSubmenu(submenu);
    this.current = false;
  }

  /**
   * Animation for showing submenu
   * @param submenu
   */
  showSubmenu(submenu) {
    this.header.classList.add('header--overlay');
    TweenLite.set(submenu, {visibility: 'visible', opacity: 0, y: '-10px'});
    TweenLite.to(submenu, 0.25, {y: 0, opacity: 1, ease: EASE_DEFAULT});
  }

  /**
   * Animation for hiding submenu
   * @param submenu
   */
  hideSubmenu(submenu) {
    this.header.classList.remove('header--overlay');
    TweenLite.to(submenu, 0.25, {y: '-10px', opacity: 0, ease: EASE_DEFAULT, onComplete: () => (
        TweenLite.set(submenu, {visibility: 'hidden'}
        ))});
  }

  /**
   * Animation for showing mobile submenu
   * @param submenu
   */
  showMobileSubmenu(submenu) {
    TweenLite.set(submenu, {visibility: 'visible', opacity: 1});
    TweenLite.to(submenu, 0.25, {maxHeight: submenu.scrollHeight, ease: EASE_DEFAULT});
  }

  /**
   * Animation for hiding mobile submenu
   * @param submenu
   */
  hideMobileSubmenu(submenu) {
    TweenLite.to(submenu, 0.25, {maxHeight: 0, ease: EASE_DEFAULT});
  }

  /**
   * Reset style in case of resize
   */
  handleResize() {
    this.current && this.closeSubmenu(this.current);

    for (let item of this.itemsWithSubmenu) {
      const submenu = item.querySelector('.menu__submenu');

      if (getViewportWidth() >= BREAKPOINT_LARGE) {
        TweenLite.set(submenu, {maxHeight: 'none', opacity: 0, visibility: 'none'});
        TweenLite.set(this.el, {x: '0%'});
        this.isOpen = false;
        document.body.style.overflow = 'auto';
      } else {
        TweenLite.set(submenu, {maxHeight: 0});
        TweenLite.set(this.el, {x: '-100%'});
        this.isOpen = false;
        this.closeSvg.style.display = 'none';
        this.burgerSvg.style.display = 'block';
      }
    }
  }

  /**
   * Handle item with submenu mouse enter
   * Used for desktop
   * @param e
   */
  onMouseEnter(e) {
    const { target } = e;
    getViewportWidth() >= BREAKPOINT_LARGE && this.openSubmenu(target);
  }

  /**
   * Handle item with submenu mouse leave
   * Used for desktop
   * @param e Event
   */
  onMouseLeave(e) {
    const { target } = e;
    getViewportWidth() >= BREAKPOINT_LARGE && this.closeSubmenu(target);
  }

  /**
   * Handle close / open submenu
   * Used for mobile and desktop
   * @param e Event
   */
  onClick(e) {
    const { currentTarget } = e;

    if (getViewportWidth() < BREAKPOINT_LARGE) {
      e.preventDefault();
      currentTarget.classList.contains('menu__item--expanded') ? this.closeSubmenu(currentTarget) : this.openSubmenu(currentTarget);
    } else if (this.current) {
      this.closeSubmenu(this.current);
    }
  }

  /**
   * Handle resize screen by client
   * Prevent menu disappearing
   */
  onResize() {
    if (this.windowWidth !== window.innerWidth) {
      this.windowWidth = window.innerWidth;
      this.handleResize();
    }
  }

  /**
   * Fired when user navigate
   */
  onNavigate() {
    this.isOpen && this.closeMenu();
  }

  /**
   * Data layer
   */
  onIconLinkClick(e) {
    const { currentTarget } = e;
    const title = currentTarget.querySelector('.icon-links__title');
    if (title !== 'Menu') {
      dataLayer.push({'event': `CTA Header ${title.innerHTML}`});
    }
  }

  /**
   * Set specific class for design
   */
  setSpecificClass() {
    const findAPro = this.el.querySelector('.menu--second .menu__item');
    findAPro.classList.add('menu__item--hidden-tablet');
    findAPro.classList.add('menu__item--find-prod');
  }

  /**
   * Bind UI Actions
   */
  bindUIActions() {
    for (let item of this.itemsWithSubmenu) {
      item.addEventListener('mouseenter', (e) => this.onMouseEnter(e));
      item.addEventListener('mouseleave', (e) => this.onMouseLeave(e));
      item.addEventListener('click', (e) => this.onClick(e));
    }

    for (let item of this.iconLinks) {
      item.addEventListener('click', (e) => this.onIconLinkClick(e));
    }

    this.toggleBtn.addEventListener('click', (e) => this.toggleMenu(e));

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Menu;
