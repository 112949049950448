import { tns } from 'tiny-slider/src/tiny-slider';
import {TimelineLite} from "gsap";
import {BREAKPOINT_SMALL, EASE_DEFAULT} from "../utils/constants";
import {getCurrentBreakpoint} from "../utils/utils";

class Gallerie {

  constructor(el, toFitWith) {
    this.el = el;
    this.content = toFitWith;
    this.gallerieWrapper = el.querySelector('.gallerie__wrapper');
    this.gallerieList = el.querySelector('.gallerie__list');
    this.gallerieNavItems = el.querySelectorAll('.gallerie-nav__item');
    this.closeButton = el.querySelector('.gallerie__close');
    this.progressBar = el.querySelector('.gallerie-nav__progress-bar');
    this.virtualVisit = el.querySelector('.virtual-visit');
    this.gallerieNavSlides = [];

    this.isDraging = false;
    this.isOpen = false;
    this.hasGallerieNav = [...this.gallerieNavItems].length > 0;

    this.gallerie = tns({
      container: '.gallerie__list',
      mode: 'gallery',
      controls: true,
      nav: false,
      item: 1,
      mouseDrag: true,
      swipeAngle: false,
      speed: 400,
      nextButton: document.querySelector('.gallerie__control--next'),
      prevButton: document.querySelector('.gallerie__control--prev'),
    });

    if (this.hasGallerieNav) {
      this.gallerieNavItems[0].style.paddingLeft = `${this.content.getBoundingClientRect().left - 15}px`;

      this.gallerieNav = tns({
        container: '.gallerie-nav__list',
        loop: false,
        controls: true,
        nav: false,
        autoWidth: true,
        mouseDrag: true,
        swipeAngle: false,
        speed: 400,
        nextButton: document.querySelector('.gallerie-nav__control--next'),
        prevButton: document.querySelector('.gallerie-nav__control--prev'),
        responsive: {
          [BREAKPOINT_SMALL]: {
            speed: 1500,
          }
        }
      });
      this.gallerieNav.events.on('transitionStart', this.onTransitionStartGallerieNav);

      const { displayIndex, slideCount } = this.gallerieNav.getInfo();
      this.updateProgressBar(displayIndex, slideCount);
    }

    this.timeline = new TimelineLite({paused: true});

    this.timeline.pause();
    this.timeline.set(this.gallerieWrapper, {visibility: 'visible'});
    this.timeline.to(this.gallerieWrapper, 0.35, {
      opacity: 1,
      ease: EASE_DEFAULT,
    });
    this.timeline.from(this.gallerieList, 0.35, {
      opacity: 0,
      ease: EASE_DEFAULT
    });

    this.bindUIActions();
    this.fitToScreen();
  }

  onTransitionStartGallerieNav(info, eventName) {
    if (getCurrentBreakpoint() !== BREAKPOINT_SMALL) {
      const gallerieNav = info.container;
      const items = info.container.children;
      const gallerieNavWidth = gallerieNav.offsetWidth;
      const gallerieNavLimit = gallerieNavWidth - window.innerWidth;
      let itemsWidth = 0;
      let limitItemIndex = 1;
      while (itemsWidth < gallerieNavLimit) {
        itemsWidth += items[limitItemIndex - 1].offsetWidth;
        limitItemIndex++;
      }
      if (info.displayIndex >= limitItemIndex) { // next index
        document.querySelector('.gallerie-nav__control--next').setAttribute('disabled', 'disabled');
      } else {
        document.querySelector('.gallerie-nav__control--next').removeAttribute('disabled');
      }
    }
  }

  /**
   * Display gallerie with animation
   * @param index int Index of slide to show
   */
  showSlider(index = 0) {
    this.gallerie.goTo(index);
    this.timeline.play();
    this.isOpen = true;
  }

  /**
   * Hide gallerie with animation
   */
  hideSlider() {
    this.timeline.reverse();
    this.isOpen = false;
  }

  /**
   * Update offset when resizing
   */
  onResize() {
    this.fitToScreen();
  }

  fitToScreen() {
    if (getCurrentBreakpoint() !== BREAKPOINT_SMALL && this.hasGallerieNav) {
      const style = this.content.currentStyle || window.getComputedStyle(this.content);
      this.gallerieNavItems[0].style.paddingLeft = `${this.content.getBoundingClientRect().left + parseInt(style.paddingLeft) - 15}px`;
    } else if (this.hasGallerieNav) {
      this.gallerieNavItems[0].style.paddingLeft = '0px';
    }
  }

  /**
   * Handle gallerie nav item click
   * @param e
   */
  onSliderNavItemClick(e) {
    if (e.currentTarget.dataset.clickDisabled === undefined) {
      this.showSlider(parseInt(e.currentTarget.dataset.index));
    }
  }

  /**
   * Event handler for closing button
   */
  onCloseButtonClick() {
    this.hideSlider();
  }

  /**
   * Event handler for escape button
   */
  onEscapeKeyDown(e) {
    if (e.key === 'Escape' && this.isOpen) {
      this.hideSlider();
    }
  }

  /**
   * Event handler for click on overlay
   */
  onGallerieWrapperClick(e) {
    const { target } = e;
    target === this.gallerieWrapper && this.hideSlider();
  }

  /**
   * Update gallerie nav position
   * @param e
   */
  updateSliderNavIndex(e) {
    const { displayIndex } = e;
    this.gallerieNav.goTo(this.virtualVisit ? displayIndex : displayIndex - 1);
  }

  /**
   * Update progress bar
   * @param index Int from 1
   * @param total gallerie length
   */
  updateProgressBar(index, total) {
    TweenLite.to(this.progressBar, 0.35, {
      width: `${index / total * 100}%`,
      ease: EASE_DEFAULT,
    });
  }

  /**
   * Event fired when index of the big slider change
   * @param e
   */
  onIndexChange(e) {
    this.updateSliderNavIndex(e);
  }

  /**
   * Event fired when index of the nav slider change
   * @param e
   */
  onNavIndexChange(e) {
    const { displayIndex, slideCount } = e;
    this.updateProgressBar(displayIndex, slideCount);
  }

  destroy() {
    this.gallerie.destroy();
    this.hasGallerieNav && this.gallerieNav.destroy();
  }

  /**
   * Add event listener for click and index changing on gallerie nav
   */
  bindUIActions() {
    window.addEventListener('resize', () => this.onResize());
    window.addEventListener('keydown', (e) => this.onEscapeKeyDown(e));

    this.closeButton.addEventListener('click', () => this.onCloseButtonClick());
    this.gallerieWrapper.addEventListener('click', e => this.onGallerieWrapperClick(e));

    this.gallerieNavItems.forEach(item => {
      item.addEventListener('mousedown', () => this.isDraging = false);
      item.addEventListener('mousemove', () => this.isDraging = true);
      item.addEventListener('mouseup', e => !this.isDraging && this.onSliderNavItemClick(e));
    });

    this.gallerie.events.on( 'indexChanged', e => this.onIndexChange(e));
    this.hasGallerieNav && this.gallerieNav.events.on( 'indexChanged', e => this.onNavIndexChange(e));
  }

}

export default Gallerie;
