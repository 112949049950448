class blockLink {
  constructor(el) {
    this.el = el;
    this.bindUIActions();
  }

  /**
   * Add listener to the .block-link element
   */
  bindUIActions() {
    this.el.addEventListener('click', (e) => this.clickHandler(e));
  }

  /**
   * clickHandler
   * @param e
   */
  clickHandler(e) {
    // if clicked element is not a link, get the link in the block and go to the address depend on target blank or ctrl or meta key pressed
    if (e.target.tagName.toLowerCase() !== 'a') {
      let link = this.el.querySelector('.item__link');
      if (link.getAttribute('target') === '_blank' || e.ctrlKey || e.metaKey) {
        window.open(link.getAttribute('href'), '_blank');
      } else {
        link.click();
      }
    }
  }
}

export default blockLink;
