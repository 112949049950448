import fetch from 'cross-fetch';
import {getGoogleRecaptcha} from "../utils/utils";

class Newsletter {
  constructor(el) {
    this.el = el;
    this.el.setAttribute('data-event', 'sites-societes.newsletter.submit');
    this.submit = this.el.querySelector('[type=submit]');
    this.captcha = null;
    this.status = 0;

    getGoogleRecaptcha().then(() => {
      this.onReCaptchaReady();
    });

    this.bindUIActions();
  }

  submitNewsletter(e) {
    e.preventDefault();
    if (!this.captcha) {
      this.captcha = grecaptcha;
    }
    this.el.classList.remove('success');
    this.el.classList.remove('danger');
    this.el.querySelectorAll('.footer-newsletter__message').forEach((node, i, src) => {
      node.remove();
    });
    this.status = 0;

    fetch(this.el.getAttribute('action'), {
      method: this.el.getAttribute('method').toUpperCase(),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(new FormData(this.el))
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(json => {
        var message = json.message;

        if (typeof message === 'object') {
          const messages = [];
          for (var key in message) {
            messages.push(message[key].map(message => message).join('<br />'));
          }
          message = messages.join('<br />');
        }
        var statusClass = this.status == 200 ? 'success' : 'danger';
        message = `<p class="footer-newsletter__message ${statusClass}">${message}</p>`;
        this.el.querySelector('.form__wrapper').insertAdjacentHTML('afterend', message);
        this.el.classList.add(statusClass);

        switch (this.status) {
          case 200:
            dataLayer.push({
              event: 'Envoi Formulaire Newsletter'
            });
            this.submit.innerHTML = '<svg class="svg-icon svg-icon--checkmark"><use xlink:href="#sprite-checkmark"></use></svg>';
            this.el.reset();
          case 400:
            grecaptcha.reset();
            break;
          default:
            this.disableForm();
            break;
        }
      })
      .catch(error => {
        console.error(error);

        this.disableForm();
      })
    ;
  }

  disableForm() {
    this.el.setAttribute('disabled', 'disabled');
    const fields = this.el.querySelectorAll('input, button');
    for (var i = 0; i < fields.length; i++) {
      fields[i].setAttribute('disabled', 'disabled');
    }
  }

  resetSubmit() {
    this.el.classList.remove('success');
    this.el.classList.remove('danger');
    this.submit.innerText = 'OK';
  }

  onReCaptchaReady() {
    var form = this.el;
    var container = form.querySelector('.g-recaptcha');
    if (container) {
      var params = {
        sitekey: container.getAttribute('data-sitekey'),
        callback: this.onReCaptchaFormSubmit.bind(form)
      };
      if (container.hasAttribute('data-size')) {
        params.size = container.getAttribute('data-size');
      }
      if (container.hasAttribute('data-badge')) {
        params.badge = container.getAttribute('data-badge');
      }
      grecaptcha.render(form.querySelector('[type=submit]'), params);
    }
  }

  onReCaptchaFormSubmit(token) {
    this.querySelector('.g-recaptcha').value = token;
    if (this.hasAttribute('data-event')) {
      this.dispatchEvent(new Event(this.getAttribute('data-event')));
    } else {
      this.submit();
    }
  }

  bindUIActions() {
    this.el.addEventListener(this.el.getAttribute('data-event'), e => this.submitNewsletter(e));
    this.el.querySelector('[type=email]').addEventListener('input', e => this.resetSubmit(e));
  }
}

export default Newsletter;
