class Tabs {

  constructor(el) {
    this.el = el;
    this.wrapper = this.el.querySelector('.tabs__contents');
    this.contents = this.el.querySelectorAll('.tabs__content');
    this.items = this.el.querySelectorAll('.tabs-nav__link');
    this.links = this.el.querySelectorAll('.tabs-nav__link');
    this.current = this.el.querySelector('.tabs__content');
    this.currentNav = this.el.querySelector('.tabs-nav__item');

    this.init();
    this.calcHeight();
    this.bindUIActions();
  }

  /**
   * Show default
   */
  init() {
    this.current.classList.add('tabs__content--active');
    this.currentNav.classList.add('tabs-nav__item--active');
  }

  /**
   * Handle click to display the selected tab
   * @param e
   */
  onLinkClick(e) {
    e.preventDefault();
    const { target } = e;
    const id = target.getAttribute('href');

    if (id !== this.current.id) {
      this.close(this.current.id);
      this.show(id);

      this.currentNav = target.parentNode;
      this.currentNav.classList.add('tabs-nav__item--active');
    }
  }

  /**
   * Add CSS Class and maintain current
   * @param id
   */
  show(id) {
    let tab = this.wrapper.querySelector(id);
    tab.classList.add('tabs__content--active');
    this.current = tab;
  }

  /**
   * Remove CSS class
   */
  close() {
    this.current.classList.remove('tabs__content--active');
    this.currentNav.classList.remove('tabs-nav__item--active');
  }

  /**
   * Take the taller tab and set wrapper height
   * Used on init and on resize
   */
  calcHeight() {
    let maxHeight = 0;

    this.contents.forEach(
      content => {
        maxHeight = content.offsetHeight > maxHeight ? content.offsetHeight : maxHeight;
      }
    );

    this.wrapper.style.height = `${maxHeight}px`;
  }

  /**
   * On resize
   */
  onResize() {
    this.calcHeight();
  }

  /**
   * Bind link and window resize
   */
  bindUIActions() {
    this.links.forEach(
      link => {
        link.addEventListener('click', (e) => this.onLinkClick(e));
      }
    );

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Tabs;