import { scrollTo } from "../utils/utils";

class Scrolltop {

  constructor(el) {
    this.el = el;
    this.bindUIActions();
    this.limitOffset = 3;
    this.footer = document.querySelector('.footer');
    this.rgpd =  document.querySelector('.rgpd');
  }

  onScroll() {
    if(this.rgpd === null && document.documentElement.scrollTop > window.innerHeight * this.limitOffset && !(document.documentElement.scrollTop > this.footer.offsetTop - window.innerHeight)) {
      this.el.classList.add('scrolltop--show');
    } else {
      this.el.classList.remove('scrolltop--show');
    }
  }

  onClick() {
    scrollTo(0);
  }

  bindUIActions() {
    window.addEventListener('scroll', () => this.onScroll());
    this.el.addEventListener('click', (e) => this.onClick(e));
  }

}

export default Scrolltop;
