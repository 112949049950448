class Sitemap {
  constructor() {
    this.sitemapLinks = document.querySelectorAll('.sitemap__link');
    this.bindUIActions();
  }

  /**
   * Bind UI Actions
   * Event listener on items
   */
  bindUIActions() {
    this.sitemapLinks.forEach( link => {
      this.addClickEvent(link);
    });
  }

  /**
   * Get the associate link and trigger a click on it
   *
   * @param link
   */
  addClickEvent(link) {
    link.addEventListener('click', () => link.parentNode.querySelector('a').click());
  }
}

export default Sitemap;
