import Highway from '@refletdigital/highway';
import SiteMap from "../components/Sitemap";
import {init} from '../init';

class Sitemap extends Highway.Renderer {
  onEnter() {
    init();
    new SiteMap();
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {

  }
}

export default Sitemap;
