import { TimelineLite, TweenLite } from "gsap";
import {
  EASE_DEFAULT,
  BREAKPOINT_LARGE,
  BREAKPOINT_SMALL,
  BREAKPOINT_MEDIUM,
  OPEN_SEARCH_RESULTS, CLOSE_SEARCH_RESULTS
} from "../utils/constants";
import fetch from "cross-fetch";
import { isBrowserOutdated, lock, unlock } from "../utils/utils";
import { tns } from 'tiny-slider/src/tiny-slider';
import { H } from '../app';
import DataLink from "./DataLink";

class Search {

  constructor() {
    this.el = document.querySelector('.search');
    this.header = document.querySelector('.header');
    this.button = this.el.querySelector('.search__toggle');
    this.wrapper = this.el.querySelector('.search__wrapper');
    this.form = this.el.querySelector('.search__form');
    this.results = this.el.querySelector('.search-results__slider');
    this.categories = this.el.querySelector('.search-results__section--categories .search-results__content');
    this.products = this.el.querySelector('.search-results__section--products .search-results__content');
    this.field = this.el.querySelector('.search__input');
    this.link = this.el.querySelector('.search-results__link');
    this.assetUrl = typeof(CATALOG_MEDIA_URL !== 'undefined') ? CATALOG_MEDIA_URL : '/catalog';

    this.productSilder = false;
    this.productData = false;

    this.controller = new AbortController();

    this.isOpen = false;
    this.isResultsOpen = false;

    this.bindUIActions();
  }

  /**
   * Toggle search form
   */
  toggle(e) {
    this.isOpen ? this.close() : this.open();
  }

  /**
   * Open search form
   */
  open() {
    this.el.classList.add('search--open');
    this.header.classList.add('header--overlay');
    this.isOpen = true;

    TweenLite.set(this.wrapper, {
      visibility: 'visible'
    });

    TweenLite.to(this.form, 0.5, {
      y: '0%',
      ease: EASE_DEFAULT,
      onComplete: () => this.field.focus()
    });
  }

  /**
   * Close search form
   */
  close() {
    this.wrapper.scrollTop = 0;
    this.el.classList.remove('search--open');
    this.header.classList.remove('header--overlay');
    this.isOpen = false;

    let timeline = new TimelineLite({
      onComplete: () => {
        TweenLite.set(this.wrapper, {
          visibility: 'hidden'
        });
      }
    });

    this.isResultsOpen && timeline.to(this.results, 0.25, {
      y: '-100%',
      ease: EASE_DEFAULT,
      onComplete: () => {
        this.clearResults();
        this.field.value = '';
        unlock();

        const event = new Event(CLOSE_SEARCH_RESULTS);
        document.dispatchEvent(event);
      }
    });
    timeline.to(this.form, 0.25, {
      y: '-100%',
      ease: EASE_DEFAULT
    });
  }

  /**
   * Open search results
   */
  openResults() {
    TweenLite.set(this.results, {
      opacity: 1,
      height: window.innerWidth < BREAKPOINT_LARGE ? window.innerHeight - this.wrapper.getBoundingClientRect().bottom + 'px' : 'auto'
    });

    TweenLite.to(this.results, 0.5, {
      y: '0%',
      ease: EASE_DEFAULT,
    });

    const event = new Event(OPEN_SEARCH_RESULTS);
    document.dispatchEvent(event);

    this.isResultsOpen = true;
    lock();
  }

  /**
   * Close search results
   */
  closeResults() {
    TweenLite.to(this.results, 0.5, {
      y: '-100%',
      ease: EASE_DEFAULT,
      onComplete: () => {
        TweenLite.set(this.results, {
          opacity: 1,
          height: 0
        });

        const event = new Event(CLOSE_SEARCH_RESULTS);
        document.dispatchEvent(event);
      }
    });

    this.isResultsOpen = false;
    unlock();
  }

  /**
   * Clear results pan
   */
  clearResults() {
    this.categories.innerHTML = '';
    this.products.innerHTML = '';
  }

  /**
   * Fetch API
   * @param query
   */
  fetch(query) {
    fetch(`${this.form.getAttribute('action')}?${this.field.getAttribute('name')}=${this.field.value}`, {
      method: this.form.getAttribute('method').toUpperCase(),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      signal: this.controller.signal
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('[Search] - Network error during fetch');
    })
    .then(json => this.onResponse(json))
    .catch(e => {
      if (e.name !== 'AbortError') {
        console.log(e.message);
      }
    });
  }

  /**
   * Handle API response
   * @param content
   */
  onResponse(content) {
    const { categories, brands, products } = content.results.data;

    if (categories.data.length || products.data.length) {
      if (categories.data.length) {
        this.setCategories(categories.data);
      }
      if (products.data.length) {
        this.setProducts(products.data);
        this.productData = true;
      }

      this.bindAjaxUI();

      this.link.setAttribute('href', `${this.form.getAttribute('action')}?${this.field.getAttribute('name')}=${this.field.value}`);
      !this.isResultsOpen && this.openResults();
    } else {
      this.isResultsOpen && this.closeResults();
    }
  }

  /**
   * Set results content
   * @param content
   */
  setCategories(content) {
    this.categories.innerHTML =
      `<ul class="search-results__list">
        ${content.map(item =>
        `<li class="item">
          <a href="/${item.path.split('/').filter((path, i) => i == 0 || i + 1 >= item.path.split('/').length).join('/')}" class="item__link">
            ${item.label}
          </a>
         </li>`).join('')}
      </ul>`;
  }

  /**
   * On Resize, init or destroy slider depends on windows size
   */
  onResize() {
    if (window.innerWidth >= parseInt(BREAKPOINT_MEDIUM)) {
      this.destroySlider();
    } else {
      this.iniSlider();
    }
  }


  /**
   * init slider for mobile if doesn't exist and results has data
   */
  iniSlider() {
    if (!isBrowserOutdated()) {
      if (!this.productSilder && this.productData) {
        this.productSilder = tns({
          container: this.products.querySelector('.search-results__list'),
          controls: false,
          nav: false,
          mouseDrag: true,
          swipeAngle: false,
          speed: 400,
          disable: false,
          edgePadding: 15,
          fixedWidth: 250,
          loop: false,
          gutter: 10,
          responsive: {
            [BREAKPOINT_SMALL]: {
              fixedWidth: 450,
            },
            [BREAKPOINT_MEDIUM]: {
              disable: true,
            }
          }
        });
      }
    }
  }

  /**
   * destroy slider if exists and set productSilder and productData to false
   */
  destroySlider() {
    this.productSilder = false;
    this.productData = false;
    if (this.productSilder) {
      this.productSilder.destroy();
    }
  }

  /**
   * Set results content
   * @param content
   */
  setProducts(content) {
    this.productData = true;
    this.products.innerHTML =
      `<ul class="search-results__list">
        ${content.map((item) =>
        `<li class="item block-link">
           <picture class="item__picture">
            <img src="${this.assetUrl}/product/${item.images.image_thumbnail.image.replace(/^\/+/g, '')}" alt="${item.images.image_thumbnail.alt ? item.images.image_thumbnail.alt : item.label}" class="image">
           </picture>
           <span class="item__categorie">
            ${item.categories.map(v => v.label).join(', ')}
          </span>
          <a href="/${item.categories[0].path.split('/').filter((path, i) => i === 0 || i + 1 >= item.categories[0].path.split('/').length).join('/')}/${item.slug}" class="item__link">
            ${item.label}
          </a>
        </li>`).join('')}
      </ul>`;
    this.iniSlider();
  }

  /**
   * Bind asynchrone content
   * Data link + Highway for links
   */
  bindAjaxUI() {
    const blockLinks = this.products.querySelectorAll('.block-link');
    blockLinks.forEach(
    dataLink => {
      new DataLink(dataLink);
    });

    H.attach(this.results.querySelectorAll('.item__link'));
  }

  /**
   * On key press on field
   */
  onInput() {
    this.controller.abort();
    this.controller = new AbortController();
    this.destroySlider();
    if (this.field.value !== '') {
      this.fetch(this.field.value);
    } else {
      this.isResultsOpen && this.closeResults();
    }
  }

  onEscapeKeyDown(e) {
    if (e.key === 'Escape' && this.isOpen) {
      this.close();
    }
  }

  /**
   * Fired when user navigate
   */
  onNavigate() {
    this.isOpen && this.close();
  }

  /**
   * Fired when user click on body
   * close the search if the click is outside .header__search container
   */
  onBodyClick(e) {
    if (this.isOpen) {
      const element = e.target;
      if(element.closest('.header__search') === null) {
        if (window.innerWidth < BREAKPOINT_LARGE) {
          this.closeResults();
        } else {
          this.close();
        }
      }
    }
  }

  /**
   * Bind UI actions
   */
  bindUIActions() {
    this.button.addEventListener('click', e => this.toggle(e));
    this.field.addEventListener('input', e => this.onInput(e));
    this.field.addEventListener('focus', e => this.onInput(e));
    window.addEventListener('keydown', e => this.onEscapeKeyDown(e));
    window.addEventListener('resize', () => this.onResize());
    document.body.addEventListener('click', e => this.onBodyClick(e));
  }
}

export default Search;
