import { TimelineLite } from 'gsap';
import { EASE_DEFAULT } from './../utils/constants';
import { getSVGIcon, lock, unlock } from "../utils/utils";

class Modal {

  constructor() {
    this.render();
    this.bindUIActions();
    this.setAnimation();
  }

  /**
   * Replace content
   * @param content Node Element
   */
  setContent(content) {
    this.inner.innerHTML = '';
    this.inner.appendChild(content);
  }

  /**
   * Open modal
   */
  open() {
    this.timeline.play();
    lock();
  }

  /**
   * Close modal
   */
  close() {
    this.timeline.reverse();
  }

  onEscapeKeyDown(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  setAnimation() {
    this.timeline = new TimelineLite({paused: true, onReverseComplete: () => unlock()});
    this.timeline.to(this.modal, 0.35, {
      opacity: 1,
      visibility: 'visible',
      ease: EASE_DEFAULT,
    });
    this.timeline.from(this.inner, 0.35, {
      y: 25,
      opacity: 0,
      ease: EASE_DEFAULT,
    });
    this.timeline.from(this.closeBtn, 0.25, {
      opacity: 0,
      ease: EASE_DEFAULT,
    });
  }

  /**
   * Bind UI Actions
   */
  bindUIActions() {
    this.closeBtn.addEventListener('click', () => this.close());
    window.addEventListener('keydown', (e) => this.onEscapeKeyDown(e));
  }

  /**
   * Append DOM
   */
  render() {
    this.modal = document.createElement('div');
    this.modal.classList.add('modal');
    this.inner = document.createElement('div');
    this.inner.classList.add('modal__inner');
    this.closeBtn = document.createElement('div');
    this.closeBtn.classList.add('modal__close');
    this.closeBtn.appendChild(getSVGIcon('close'));
    this.modal.appendChild(this.inner);
    this.modal.appendChild(this.closeBtn);
    document.body.appendChild(this.modal);
  }
}

const modal = new Modal();
Object.freeze(modal);

export default modal;
