import Highway from '@refletdigital/highway';
import { TweenLite } from 'gsap';
import {EASE_DEFAULT} from "../utils/constants";

class Default extends Highway.Transition {
  in({ from, to, done }) {
    window.scrollTo(0, 0);

    TweenLite.from(to, 0.5,
      {
        opacity: 0,
        y: 10, ease: EASE_DEFAULT,
        onComplete: () => {
          TweenLite.set(to, {clearProps: 'all'});
          TweenLite.set('.main', {height: 'auto'});
          done();
        }},
    );
  }

  out({ from, done }) {
    const main = document.querySelector('.main');

    TweenLite.set(main, {height: main.scrollHeight});

    TweenLite.fromTo(from, 0.5,
      { opacity: 1, y: 0 },
      {
        opacity: 0,
        y: -10,
        ease: EASE_DEFAULT,
        onComplete: () => {
          from.remove();
          done();
        }
      }
    );
  }
}

export default Default;
