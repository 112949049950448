import {TimelineLite} from "gsap";
import {EASE_DEFAULT} from "../utils/constants";
import { scrollToAnchor } from "../utils/utils";

class Sidebar {

  constructor(el, startPos = 0, endPos = document.documentElement.scrollHeight - window.innerHeight) {
    this.el = el;
    this.titles = this.getTitles();
    this.content = this.el.querySelector('.sidebar__content');
    this.content.innerHTML = this.getMarkup(this.titles);
    this.items = this.el.querySelectorAll('.sidebar__item');
    this.button = this.el.querySelector('.sidebar__toggle');
    this.progressBar = this.el.querySelector('.sidebar__progressbar');
    this.observer = null;
    this.isOpen = false;
    this.isFirstOpening = true;
    this.startPos = startPos;
    this.endPos = endPos;

    if (this.items.length > 0) {
      this.timeline = new TimelineLite({ paused:true });
      this.timeline.to(this.el, 1, {x: 0, y: '-50%', ease: EASE_DEFAULT});
      this.timeline.staggerFrom(this.items, 0.5, {x: '-15px', opacity: 0, ease: EASE_DEFAULT}, 0.1, '=-0.65');

      this.initObserver();
      this.bindUIActions();
    } else {
      this.el.remove();
    }
  }

  open() {
    this.el.classList.add('sidebar--open');
    this.timeline.play();
    this.isOpen = true;
  }

  close() {
    this.el.classList.remove('sidebar--open');
    this.timeline.reverse();
    this.isOpen = false;
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }

  /**
   * Handle Link Click
   * @param e Event
   */
  onLinkClick(e) {
    const { target } = e;
    e.preventDefault();
    scrollToAnchor(target);
  }

  onScroll() {
    const pos = document.documentElement.scrollTop * 100 / (document.documentElement.scrollHeight - window.innerHeight);
    this.progressBar.style.height = `${pos}%`;

    if (document.documentElement.scrollTop < this.startPos - this.el.getBoundingClientRect().top
      || document.documentElement.scrollTop > this.endPos - this.el.getBoundingClientRect().bottom) {
      this.isOpen && this.close();
    } else if (this.isFirstOpening === true) {
      this.open();
      this.isFirstOpening = false;
    }
  }

  /**
   * Basic observer
   */
  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.onIntersect(entry);
        }
      });
    });
  }

  /**
   * Fired when observed element appear on screen
   * @param entry
   */
  onIntersect(entry) {
    this.setActiveLink(`#${entry.target.id}`);
  }

  /**
   * Set active link by href
   * @param href String must be an anchor #title
   */
  setActiveLink(href) {
    this.items.forEach((item) => {
      const link = item.querySelector('.sidebar__link');
      if (link.getAttribute('href') === href) {
        item.classList.add('sidebar__item--active');
      } else {
        item.classList.remove('sidebar__item--active')
      }
    });
  }

  /**
   * Make an array of H2/H3 find in .cmm-content
   * @returns {Array}
   */
  getTitles() {
    const titles = document.querySelectorAll('.cms-content h2, .cms-content h3');
    const summary = [];

    titles.forEach( (title, i) => {
      let type = title.tagName;
      let text = title.innerHTML;
      let id = `title-${i}`;
      let subtitles = [];
      let obj = {
        text,
        id,
        subtitles
      };

      title.id = id;
      type === 'H2' && summary.push(obj);
      type === 'H3' && summary[summary.length - 1].subtitles.push(obj);
    });

    return summary;
  }

  /**
   * Create markup for sidebar list
   * @param titles
   * @returns {string}
   */
  getMarkup(titles) {
    if (titles.length > 0) {
      return `<ul class="sidebar__list">
                ${titles.map(item =>
                  `<li class="sidebar__item sidebar__item--active">
                    <a href="#${item.id}" class="sidebar__link" data-router-disabled>${item.text}</a>
                    ${item.subtitles.length > 0 ?
                      `<ul class="sidebar__sublist">
                        ${item.subtitles.map( subitem =>
                        `<li class="sidebar__item">
                           <a href="#${subitem.id}" class="sidebar__link" data-router-disabled>${subitem.text}</a>
                        </li>`
                      ).join('')}
                      </ul>` : ''}
                   </li>`
                ).join('')}
              </ul>`
    } else {
      return '';
    }
  }

  /**
   * Bind UI Actions
   * Event listener and observer on items
   */
  bindUIActions() {
    this.items.forEach((item) => {
      const link = item.querySelector('.sidebar__link');
      const href = link.getAttribute('href');
      const target = document.querySelector(href);
      link.addEventListener('click', e => this.onLinkClick(e));
      this.observer.observe(target);
    });

    this.button.addEventListener('click', () => this.toggle());

    window.addEventListener('scroll', () => this.onScroll());
  }
}

export default Sidebar;
