import Highway from '@refletdigital/highway';
import Parallax from "../utils/parallax";
import Sidebar from "../components/Sidebar";
import SharingLinks from "../components/SharingLinks";
import {init} from '../init';
import {EASE_DEFAULT} from "../utils/constants";
import ShiftedContent from "../components/ShiftedContent";

class Blog extends Highway.Renderer {
  onEnter() {
    init();
    this.el = document.querySelector('.page');

    const sharingLinksBlocks = document.querySelectorAll('.sharing-links');
    for (let item of sharingLinksBlocks) {
      new SharingLinks(item);
    }

    const shiftedContent = document.querySelector('.contents--shift');
    this.shiftedContent = shiftedContent ? new ShiftedContent(shiftedContent) : null;
    this.iconContents = document.querySelector('.contents--border-icon');
    this.bindUIActions();
  }

  onLeave() {

  }

  onEnterCompleted() {
    const page = document.querySelector('.page');
    const bg = document.querySelector('.page__background');
    let sidebarStartPos = 0;
    let sidebarEndPos = page.getBoundingClientRect().bottom + document.documentElement.scrollTop;
    if (bg) {
      const bgPosTop = bg.parentNode.getBoundingClientRect().top + document.documentElement.scrollTop;

      const img = document.querySelector('.page__picture');
      const imgPosTop = img.getBoundingClientRect().top + document.documentElement.scrollTop;
      const imgHeight = img.offsetHeight;

      const bgOffset = imgPosTop + (imgHeight / 2) - bgPosTop;
      sidebarStartPos = imgPosTop + (imgHeight / 2);

      TweenLite.set(bg, {
        top: bgOffset,
      });

      TweenLite.to(bg, 0.5, {
        opacity: 1,
        ease: EASE_DEFAULT,
        onComplete: () => {
          //new Parallax([bg], 0.15);
        }
      });
    }

    const sidebar = document.querySelector('.sidebar');
    sidebar && new Sidebar(sidebar, sidebarStartPos, sidebarEndPos);
  }

  onLeaveCompleted() {
    this.shiftedContent && this.shiftedContent.destroy();
  }

  onCatalogClick() {
    dataLayer.push({'event': 'CTA Lire Magazine Idées Déco'});
  }

  onItemClick(e) {
    const { currentTarget } = e;
    const btnLabel = currentTarget.closest('.item').querySelector('.button').innerHTML.trim().toLowerCase();
    if (btnLabel === 'trouver un magasin') {
      dataLayer.push({'event': 'CTA Magasin Électricité'});
    }
  }

  bindUIActions() {
    const catalog = document.querySelector('.contents--catalog .item');
    catalog && catalog.addEventListener('click', () => this.onCatalogClick());
    if (this.iconContents) {
      const items = this.iconContents.querySelectorAll('.item__link');
      items.forEach(item => (
        item.addEventListener('click', (e) => this.onItemClick(e))
      ))
    }
  }
}

export default Blog;
