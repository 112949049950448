import {BREAKPOINT_FULL, BREAKPOINT_XLARGE} from "../utils/constants";
import {getCurrentBreakpoint} from "../utils/utils";

class Advertising {
  constructor(el) {
    this.el = el;
    this.isFloating = this.el.classList.contains('advertising--floating');
    this.bindUIActions();
    this.updateDesign();
  }

  /**
   * Close ad
   */
  close() {
    this.el.remove();
  }

  /**
   * On resize
   */
  onResize() {
    this.updateDesign();
  }

  /**
   * Ugly but design needs
   * For floating ad, change button style by viewport size
   */
  updateDesign() {
    const currentBreakpoint = getCurrentBreakpoint();
    const buttons = this.el.querySelectorAll('.button');

    if ((currentBreakpoint === BREAKPOINT_XLARGE ||
      currentBreakpoint === BREAKPOINT_FULL)
      && this.isFloating) {
      buttons.forEach(button => {
        button.classList.remove('button--no-bg');
        button.classList.remove('button--no-bg-white');
        button.classList.add('button--regular');
        button.classList.add('button--regular-white');
      })
    } else {
      buttons.forEach(button => {
        button.classList.remove('button--regular');
        button.classList.remove('button--regular-white');
        button.classList.add('button--no-bg');
        button.classList.add('button--no-bg-white');
      })
    }
  }

  /**
   * Bind UI Actions
   * Resize and close button click
   */
  bindUIActions() {
    const close = this.el.querySelector('.advertising__close');
    close && close.addEventListener('click', () => this.close());

    window.addEventListener('resize', () => this.onResize());
  }
}

export default Advertising;
