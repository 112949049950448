import {BREAKPOINT_MEDIUM, BREAKPOINT_SMALL, EASE_DEFAULT} from "../utils/constants";
import { tns } from 'tiny-slider/src/tiny-slider';

class ShiftedContent {

  constructor(el) {
    this.slider = tns({
      autoWidth: true,
      container: el,
      controls: false,
      nav: false,
      mouseDrag: true,
      swipeAngle: false,
      speed: 400,
      disable: false,
      edgePadding: 15,
      fixedWidth: 250,
      loop: false,
      responsive: {
        [BREAKPOINT_SMALL] : {
          fixedWidth: 450,
        },
        [BREAKPOINT_MEDIUM] : {
          disable: true,
        }
      }
    });
  }

  destroy() {
    this.slider.destroy();
  }
}

export default ShiftedContent;
