class Quote {
  constructor(el) {
    this.el = el;
    this.origin = this.parseOrigin(ESPACE_AUBADE_URL);
    this.productId = this.el.getAttribute('data-product-id');
    this.button = {
      messageText: '',
      messageClass: 'info',
      buttonText: 'Voir sur le site Espace Aubade',
      buttonHref: this.el.getAttribute('data-product-url'),
      buttonClass: 'info',
      layerEvent: 'Accès Fiche Produit'
    };
    this.bounced = false;
    this.quotes = JSON.parse(localStorage.getItem('ea.quotes'));
    if (this.quotes === null || !Array.isArray(this.quotes)) {
      this. quotes = [];
    }

    this.bindUIActions();
    this.displayIframeOrLinktoProduct();
  }

  parseOrigin(origin) {
    origin = decodeURI(origin);
    var anchor = document.createElement('a');
    anchor.href = origin;
    return anchor.origin;
  }

  addToLocalStorage(id) {
    const quotes = [...this.quotes];
    const productId = this.productId;
    if (quotes.indexOf(productId) === -1) {
      this.quotes.push(id);
      localStorage.setItem('ea.quotes', JSON.stringify(this.quotes))
    }
  }

  verifyOrigin(origin) {
    return this.origin === origin;
  }

  verifyEvent(e) {
    if (typeof e != 'string') {
      return false;
    }
    if (!Array.isArray(e.match(/^ea\.quote\.product\.add\.(error|success|failure)$/))) {
      return false;
    }
    return true;
  }

  displayIframeOrLinktoProduct() {
    const quotes = [...this.quotes];
    const productId = this.productId;
    if (quotes.indexOf(productId) < 0) {
      var frame = `<iframe id="product-quote" src="${this.origin}/frames/quote?product-id=${this.el.getAttribute('data-product-id')}&theme=site-societe" frameborder="0"></iframe>`;
      this.el.insertAdjacentHTML('beforeend', frame);
      this.frame = this.el.querySelector('#product-quote');
    } else {
      this.button.messageText = 'Votre produit a bien été ajouté. Vous pouvez ajouter d\'autres produits, ou finaliser votre demande.';
      this.button.messageClass = 'success';
      this.button.buttonText = 'Finaliser ma demande';
      this.button.buttonHref = this.el.getAttribute('data-quote-url');
      this.button.buttonClass = 'success';
      this.button.layerEvent = 'Finaliser ma demande';
      this.displayLinkToProduct();
    }
  }

  displayLinkToProduct() {
    this.el.insertAdjacentHTML('beforeend', `<a class="product-quote__button button button--regular ${this.button.buttonClass}" rel="noopener" target="_blank" href="${this.button.buttonHref}">${this.button.buttonText}</a>`);
    if (this.button.messageText.length) {
      this.el.insertAdjacentHTML('afterend', `<p class="product-quote__message ${this.button.messageClass}">${this.button.messageText}</p>`);
    }
    this.el.querySelector('.product-quote__button').addEventListener('click', e => {
      dataLayer.push({
        event: `Clic bouton ${this.button.layerEvent}`,
        typeProduit: this.el.getAttribute('data-product-label')
      });
    });
  }

  onMessage(e) {
    if (
      this.verifyOrigin(e.origin)
      && this.verifyEvent(e.data)
      && !this.bounced
    ) {
      this.bounced = true;
      var status = e.data.replace(/^ea\.quote\.product\.add\.(error|success|failure)$/, '$1');
      switch (status) {
        case 'success':
          dataLayer.push({
            event: 'Clic bouton Obtenir un devis',
            typeProduit: this.el.getAttribute('data-product-label')
          });
          this.button.messageText = 'Votre produit a bien été ajouté. Vous pouvez ajouter d\'autres produits, ou finaliser votre demande.';
          this.button.messageClass = 'success';
          this.button.buttonText = 'Finaliser ma demande';
          this.button.buttonHref = this.el.getAttribute('data-quote-url');
          this.button.buttonClass = 'success';
          this.button.layerEvent = 'Finaliser ma demande';
          this.addToLocalStorage(this.productId);
          break;
        case 'error':
          this.button.messageText = 'Une erreur est survenue. Veuillez rééssayer ou faire votre demande de devis directement depuis le site Espace Aubade.';
          this.button.messageClass = 'danger';
          this.button.buttonText = 'Voir sur le site Espace Aubade';
          this.button.buttonHref = this.el.getAttribute('data-product-url');
          this.button.buttonClass = 'danger';
          this.button.layerEvent = 'Accès Fiche Produit';
          break;
        case 'failure':
        default:
          this.button.messageText = '';
          this.button.messageClass = 'info';
          this.button.buttonText = 'Voir sur le site Espace Aubade';
          this.button.buttonHref = this.el.getAttribute('data-product-url');
          this.button.buttonClass = 'info';
          this.button.layerEvent = 'Accès Fiche Produit';
          break;
      }
      this.frame.remove();
      this.displayLinkToProduct();
    }
  }

  bindUIActions() {
    window.addEventListener('message', e => this.onMessage(e));
  }
}

export default Quote;
