import Highway from '@refletdigital/highway';
import Parallax from "../utils/parallax";
import { getCurrentBreakpoint, isBrowserOutdated } from "../utils/utils";
import {
  BREAKPOINT_FULL,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
  BREAKPOINT_XLARGE, EASE_DEFAULT
} from "../utils/constants";
import {init} from '../init';
import BlockLink from "../components/Blocklink";
import {H} from '../app'
import {TimelineLite, TweenLite} from "gsap";
import LogoFilter from '../components/LogoFilter'

class Results extends Highway.Renderer {
  onEnter() {
    init();

    this.el = document.querySelector('.page');
    this.products = this.el.querySelector('.products');
    this.filters = this.el.querySelectorAll('.filters__link');
    if (this.products) {
      this.detachedProducts = this.products.cloneNode(true);
      this.items = this.detachedProducts.querySelectorAll('.item');
      //this.parallax = new Parallax(this.getItemsToParallax(), 0.05);
    } else {
      const LogoFilterBlocks = document.getElementById('produits').querySelectorAll('.item.block-link');
      for (let item of LogoFilterBlocks) {
        if (!isBrowserOutdated()) {
          new LogoFilter(item);
        } else {
          item.querySelector('.item__logo').classList.remove('item__logo--hidden');
        }
      }
    }

    this.bindUIActions();
  }

  onLeave() {

  }

  onEnterCompleted() {

  }

  onLeaveCompleted() {

  }

  /**
   * Get items to parallax by viewport
   * @returns Array of items
   */
  getItemsToParallax() {
    switch (getCurrentBreakpoint()) {
      case BREAKPOINT_FULL :
      case BREAKPOINT_XLARGE :
        return this.products.querySelectorAll('.item:not(.item--hidden):nth-child(3n - 1)');
      case BREAKPOINT_LARGE :
      case BREAKPOINT_MEDIUM :
      case BREAKPOINT_SMALL :
        return [];
      default :
        return [];
    }
  }

  onFilterClick(e) {
    const { target } = e;

    this.filters.forEach(
      item => {
        item.parentNode.classList.remove('filters__item--active');
      }
    );

    target.parentNode.classList.add('filters__item--active');

    this.filter(target.dataset.filter);
  }

  /**
   * Filter
   * Remove items from DOM, filter on detached items, append to DOM
   * Rebuild link event (highway + block link)
   * @param filter
   */
  filter(filter) {
    let tween = new TimelineLite();

    tween.to(this.products, 0.5, {
      opacity: 0,
      y: 25,
      ease: EASE_DEFAULT,
      onComplete: () => {
        this.products.innerHTML = '';

        this.items.forEach(
          item => {
            if (item.dataset.category === filter || filter === '') {
              this.products.appendChild(item);
            }
          }
        );

        const links = this.products.querySelectorAll('a');
        H.attach(links);

        const blockLinks = this.products.querySelectorAll('.block-link');
        blockLinks.forEach(
          blockLink => {
            new BlockLink(blockLink);
          }
        );

        this.updateParallax();
      }
    });
    tween.to(this.products, 0.5, {
      opacity: 1,
      y: 0,
      ease: EASE_DEFAULT,
    });
  }

  /**
   * Update parallax
   * Useful for filtering and resizing
   */
  updateParallax() {
    //this.parallax.clear();
    //this.parallax.fill(this.getItemsToParallax());
  }

  /**
   * Update parallax on resize
   */
  onResize() {
    //this.updateParallax();
  }

  /**
   * Handle on resize
   */
  bindUIActions() {
    window.addEventListener('resize', () => this.onResize());

    this.filters.forEach(
      item => {
        item.addEventListener('click', (e) => this.onFilterClick(e));
      }
    );
  }
}

export default Results;
