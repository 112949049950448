import { tns } from 'tiny-slider/src/tiny-slider';

class CarouselHp {
  constructor(el) {
    this.el = el;
    this.counter = el.parentElement.querySelector('.carousel-hp__counter');
    this.prevButton = document.querySelector('.carousel-hp__control--prev');
    this.nextButton = document.querySelector('.carousel-hp__control--next');
    this.autoplayBtn = document.querySelector('.carousel-hp__play');
    this.autoplayText = {
      stop: '▶<span class="carousel-hp__play--label">LECTURE</span>',
      play: '❚❚<span class="carousel-hp__play--label">PAUSE</span>'
    };
    this.timer = null;
    this.autoplay = true;
    this.autoplayTimeout = 5000;
    this.carousel = tns({
      container: '.carousel-hp',
      mode: 'carousel',
      controls: true,
      nav: false,
      item: 1,
      loop: false,
      rewind: true,
      mouseDrag: true,
      swipeAngle: false,
      speed: 400,
      preventScrollOnTouch: 'force',
      prevButton: this.prevButton,
      nextButton: this.nextButton,
    });

    this.bindUIActions();
  }

  /**
   * Autoplay
   * call goTo next slide every setting interval time
   */
  autoPlayOn() {
    this.autoplay = true;
    clearInterval(this.timer);
    this.updateAutoplayBtn();
    this.timer = setInterval(() => this.carousel.goTo('next'), this.autoplayTimeout);
  }

  /**
   * autoPlayOff
   * Stop autoplay
   */
  autoPlayOff() {
    this.autoplay = false;
    clearInterval(this.timer);
    this.updateAutoplayBtn();
  }

  /**
   * Prev next button click handler
   * @param e
   */
  handleClick(e) {
    this.autoPlayOff();
  }

  /**
   * Toggle play/pause
   * @param e
   */
  togglePlayPause(e) {
    if (this.autoplay) {
      this.autoPlayOff();
    } else {
      this.autoPlayOn();
    }
  }

  /**
   * updade autoplay button depends on autoplay
   */
  updateAutoplayBtn() {
    this.autoplayBtn.innerHTML = this.autoplay ? this.autoplayText.play : this.autoplayText.stop;
  }

  /**
   * update counter
   * display the current slide position
   */
  updateCounter() {
    this.counter.innerHTML = `${this.carousel.getInfo().displayIndex}/${this.carousel.getInfo().slideCount}`;
  }

  /**
   * Destroy the carousel
   */
  destroy() {
    this.carousel.destroy();
  }

  bindUIActions() {
    this.carousel.events.on('transitionEnd', () => this.updateCounter());
    if (this.autoplay) {
      this.autoPlayOn();
    } else {
      this.autoPlayOff();
    }
    this.prevButton.addEventListener('click', (e) => this.handleClick(e));
    this.nextButton.addEventListener('click', (e) => this.handleClick(e));
    this.carousel.events.on('dragStart', (e) => this.handleClick(e));
    this.carousel.events.on('touchStart', (e) => this.handleClick(e));
    this.autoplayBtn.addEventListener('click', (e) => this.togglePlayPause(e));
    this.el.classList.remove('loading');
  }
}

export default CarouselHp;
